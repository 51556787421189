import { Constants } from '../Utils/Constants';
import moment from 'moment';

const findDamage = (odontogram, setOdontogram, toothNumber, damage, id) => {
  let toothResult = -1;
  if (id === '') {
    toothResult = toothNumber.start;
  } else {
    toothResult = id;
  }
  let find = odontogram.find((item) => item.id === `tooth_${toothResult}`);
  const newArr = odontogram.slice();
  let indice2 = -1;
  var indice = newArr.indexOf(find);
  if (toothNumber.length !== 0 && toothNumber.end.length !== 0) {
    let find2 = odontogram.find(
      (item) => item.id === `tooth_${toothNumber.end}`
    );
    indice2 = newArr.indexOf(find2);
  }
  if (indice2 === -1) indice2 = indice;
  function* range(a, b) {
    let aux = 0;
    if (a > b) {
      aux = b;
      b = a;
      a = aux;
    }
    for (var i = a; i <= b; ++i) yield i;
  }

  let drawSelect = Array.from(range(indice, indice2));
  let auxDraw = newArr[indice]['damages'];
  let exists = false;
  auxDraw.forEach((element) => {
    if (`${damage}_tooth_${toothResult}` === element.id) {
      return (exists = true);
    }
  });
  if (exists) {
    if (toothNumber.start === toothNumber.end) {
      const filteredItems = auxDraw.filter(
        (item) => item.id !== `${damage}_tooth_${toothResult}`
      );
      newArr[indice]['damages'] = filteredItems;
      setOdontogram(newArr);
    }
  }
  return { exists, auxDraw, newArr, drawSelect };
};

export const handleClickCreateCheckBox = (
  odontogram,
  setOdontogram,
  toothNumber,
  damage,
  id,
  id_cita,
  especialista,
  especialista_id,
  asunto,
  color,
  check
) => {
  // console.log(odontogram);
  setOdontogram((tempOdon) => {
    return tempOdon.map((item) => {
      if (item.name === id) {
        const damages = item.damages.find((data) => data.damage === damage);
        let arrDamage = item.damages;
        let arrCheckBox = item.checkBoxes;
        if (damages) {
          arrCheckBox = arrCheckBox.map((element2) => {
            if (element2.id === check.id) {
              const temColor = element2.color.find(
                (col) => col.toUpperCase() === color.toUpperCase()
              );
              let setColor = [];
              if (temColor) {
                setColor = element2.color.filter(
                  (col) => col.toUpperCase() !== color.toUpperCase()
                );
              } else {
                setColor = [color, ...element2.color];
              }
              return {
                ...element2,
                color: setColor,
              };
            } else {
              return element2;
            }
          });
          const countCheck = arrCheckBox.find((product) => {
            return product.color.some((colored) => {
              return colored.toUpperCase() === color.toUpperCase();
            });
          });
          if (countCheck) {
          } else {
            arrDamage = item.damages.filter((data) => data.damage !== damage);
          }
        } else {
          let breakStart = item.name;
          let breakEnd = item.name;
          let point = item.name;
          let id = `${damage}_${item.id}`;
          let x = item.x;
          let y = item.y;
          let type = item.type;
          arrDamage = [
            ...arrDamage,
            drawDamage(
              id,
              damage,
              x,
              y,
              type,
              breakStart,
              breakEnd,
              point,
              id_cita,
              moment().format('YYYY-MM-DD HH:mm:ss'),
              especialista,
              especialista_id,
              asunto
            ),
          ];
          arrCheckBox = arrCheckBox.map((element2) => {
            if (element2.id === check.id) {
              const temColor = element2.color.find(
                (col) => col.toUpperCase() === color.toUpperCase()
              );
              let setColor = [];
              if (temColor) {
                setColor = element2.color.filter(
                  (col) => col.toUpperCase() !== color.toUpperCase()
                );
              } else {
                setColor = [color, ...element2.color];
              }
              return {
                ...element2,
                color: setColor,
              };
            } else {
              return element2;
            }
          });
        }
        return {
          ...item,
          damages: arrDamage,
          checkBoxes: arrCheckBox,
        };
      } else {
        return item;
      }
    });
  });
};

const clearDamage = (odontogram, setOdontogram, toothNumber, damage, id) => {
  let ClickId = '';
  if (typeof id === 'undefined' || id === '') {
    ClickId = '';
    if (toothNumber.start.length === 0) return;
  } else ClickId = id;

  let toothResult = -1;
  if (ClickId === '') {
    toothResult = toothNumber.start;
  } else {
    toothResult = ClickId;
  }

  let newArr = odontogram.slice();
  const find = newArr.find((item) => item.id === `tooth_${toothResult}`);
  let indice = newArr.indexOf(find);
  let indice2 = -1;
  if (toothNumber.length !== 0 && toothNumber.end.length !== 0) {
    let find2 = odontogram.find(
      (item) => item.id === `tooth_${toothNumber.end}`
    );
    indice2 = newArr.indexOf(find2);
  }
  if (indice2 === -1) indice2 = indice;

  function* range(a, b) {
    let aux = 0;
    if (a > b) {
      aux = b;
      b = a;
      a = aux;
    }
    for (var i = a; i <= b; ++i) yield i;
  }

  let drawSelect = Array.from(range(indice, indice2));

  drawSelect.forEach((item) => {
    newArr[item]['damages'] = [];
    setOdontogram(newArr);
  });
};

export const handleClickCreate = (
  odontogram,
  setOdontogram,
  toothNumber,
  damage,
  id,
  id_cita,
  especialista,
  especialista_id,
  asunto,
  fillTextTooth
) => {
  let ClickId = '';
  if (typeof id === 'undefined' || id === '') {
    ClickId = '';
    if (toothNumber.start.length === 0) return;
  } else ClickId = id;

  if (damage === -1) {
    clearDamage(odontogram, setOdontogram, toothNumber, damage, id);
  } else {
    let { exists, auxDraw, newArr, drawSelect } = findDamage(
      odontogram,
      setOdontogram,
      toothNumber,
      damage,
      ClickId
    );
    if (!exists) {
      drawSelect.forEach((element) => {
        let breakStart = newArr[drawSelect[0]]['name'];
        let breakEnd = newArr[drawSelect[drawSelect.length - 1]]['name'];
        let point = newArr[element]['name'];
        auxDraw = newArr[element]['damages'];
        let id = `${damage}_${newArr[element]['id']}`;
        let x = newArr[element]['x'];
        let y = newArr[element]['y'];
        let type = newArr[element]['type'];
        auxDraw = [
          ...auxDraw,
          drawDamage(
            id,
            damage,
            x,
            y,
            type,
            breakStart,
            breakEnd,
            point,
            id_cita,
            moment().format('YYYY-MM-DD HH:mm:ss'),
            especialista,
            especialista_id,
            asunto,
            fillTextTooth
          ),
        ];
        newArr[element]['damages'] = auxDraw;
      });
      setOdontogram(newArr);
    }
  }
};

const setTextBoxPositionY = (type, name) =>
  type === 1
    ? (name >= 51 && name <= 55) || (name >= 61 && name <= 65)
      ? -218
      : -98
    : (name >= 81 && name <= 85) || (name >= 71 && name <= 75)
      ? 265
      : 145;

export const drawDamage = (
  id,
  damage,
  x,
  y,
  type,
  breakStart,
  breakEnd,
  point,
  id_cita,
  date,
  especialista,
  especialista_id,
  asunto,
  fillTextTooth
) => {
  let draw;
  const positionTextY = setTextBoxPositionY(type, point);
  let all = Constants();
  switch (damage) {
    case -2:
      if (type === 0) {
        x = x - 4;
      } else {
        x = x - 4;
      }
      draw = (ctx, shape) => {
        //Shape0;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(42, 3);
        ctx.stroke();

        //Shape1;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(42, 3);
        ctx.lineTo(42, 83);
        ctx.stroke();

        //Shape2;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(42, 83);
        ctx.lineTo(2, 83);
        ctx.stroke();

        //Shape3;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(2, 83);
        ctx.lineTo(2, 3);
        ctx.stroke();

        //Shape4;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(NaN, NaN);
        ctx.stroke();

        //Shape6;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(35, 6);
        ctx.lineTo(35, 6);
        ctx.lineTo(35, 6);
        ctx.lineTo(35, 6);
        ctx.lineTo(35, 6);
        ctx.stroke();

        //Shape7;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(NaN, NaN);
        ctx.stroke();

        //Shape10;
        ctx.shadowColor = 'rgba(0,0,0,0)';
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 1;
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.beginPath();
        ctx.moveTo(35, 8);
        ctx.bezierCurveTo(36, 9, 37, 10, 37, 10);
        ctx.bezierCurveTo(38, 9, 39, 7, 39, 6);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_EXTRUIDO:
      // DIENTE_EXTRUIDO - 9 - 9
      if (type === 0) {
        x = x + 25;
        y = y + 98;
      } else {
        x = x + 8;
        y = y - 18;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(7, 33);
        ctx.lineTo(12, 23);
        ctx.lineTo(2, 23);
        ctx.lineTo(7, 33);
        ctx.fillStyle = '#0000ff';
        ctx.fill();
        ctx.moveTo(7, 23);
        ctx.lineTo(7, 8);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.FRACTURA:
      // FRACTURA - 5 - 5
      if (type === 0) {
        x = x + 33;
        y = y + 60;
      } else {
        y = y + 25;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(32, 28);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_AUSENTE:
      // DIENTE_AUSENTE - 4 - 4
      if (type === 0) {
        x = x + 1;
        y = y + 3;
      } else {
        y = y + 20;
      }
      draw = (ctx, shape) => {
        // ctx.strokeStyle = "#0000ff";
        // ctx.lineWidth = 2;
        // ctx.beginPath();
        // ctx.moveTo(2, 3);
        // ctx.lineTo(32, 63);
        // ctx.moveTo(32, 3);
        // ctx.lineTo(2, 63);
        // ctx.stroke();
        // ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_EXTRACCION:
      if (type === 0) {
        x = x + 3;
        y = y + 3;
      } else {
        x = x + 3;
        y = y + 20;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#FF0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(32, 63);
        ctx.moveTo(32, 3);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.EXODONCIA:
      if (type === 0) {
        x = x + 3;
        y = y + 3;
      } else {
        x = x + 3;
        y = y + 20;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#FF0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(32, 63);
        ctx.moveTo(32, 3);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.EXODONCIA_TERMINO:
      if (type === 0) {
        x = x + 3;
        y = y + 3;
      } else {
        x = x + 3;
        y = y + 20;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(32, 63);
        ctx.moveTo(32, 3);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CORONA_TEMPORAL:
      // CORONA_TEMPORAL - 3 - 3
      if (type === 0) {
        x = x - 0;
        y = y - 5;
      } else {
        x = x - 0;
        y = y + 50;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 11, 37, 21);
        ctx.bezierCurveTo(37, 30, 29, 38, 20, 38);
        ctx.bezierCurveTo(10, 38, 2, 30, 2, 21);
        ctx.bezierCurveTo(2, 11, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CORONA_TALLADO:
      // CORONA_TEMPORAL - 3 - 3
      if (type === 0) {
        x = x - 0;
        y = y - 5;
      } else {
        x = x - 0;
        y = y + 50;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#e7ed2d';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 11, 37, 21);
        ctx.bezierCurveTo(37, 30, 29, 38, 20, 38);
        ctx.bezierCurveTo(10, 38, 2, 30, 2, 21);
        ctx.bezierCurveTo(2, 11, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CORONA_CEMENTACION:
      // CORONA_TEMPORAL - 3 - 3
      if (type === 0) {
        x = x - 0;
        y = y - 5;
      } else {
        x = x - 0;
        y = y + 50;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 11, 37, 21);
        ctx.bezierCurveTo(37, 30, 29, 38, 20, 38);
        ctx.bezierCurveTo(10, 38, 2, 30, 2, 21);
        ctx.bezierCurveTo(2, 11, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CORONA_DEFINITIVA:
      // CORONA_DEFINITIVA - 2 - 2
      if (type === 0) {
        x = x - 0;
        y = y - 5;
      } else {
        x = x - 0;
        y = y + 50;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 11, 37, 21);
        ctx.bezierCurveTo(37, 30, 29, 38, 20, 38);
        ctx.bezierCurveTo(10, 38, 2, 30, 2, 21);
        ctx.bezierCurveTo(2, 11, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.EDENTULO_TOTAL:
      // EDENTULOA_TOTAL - 31 - C
      if (type === 0) {
        y = y + 5;
      } else {
        y = y + 67;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PERNO_MUNON:
      // PERNO_MUNON - 30 - X
      if (type === 0) {
        x = x + 30;
        y = y + 60;
      } else {
        x = x + 5;
        y = y + 27;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 38);
        ctx.lineTo(22, 38);
        ctx.lineTo(22, 58);
        ctx.lineTo(2, 58);
        ctx.lineTo(2, 38);
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PERNO_METAL_TALLADO_PERNO:
      // PERNO_MUNON - 30 - X
      if (type === 0) {
        x = x + 30;
        y = y + 60;
      } else {
        x = x + 5;
        y = y + 27;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 38);
        ctx.lineTo(22, 38);
        ctx.lineTo(22, 58);
        ctx.lineTo(2, 58);
        ctx.lineTo(2, 38);
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PERNO_METAL_CEMENTACION:
      // PERNO_MUNON - 30 - X
      if (type === 0) {
        x = x + 30;
        y = y + 60;
      } else {
        x = x + 5;
        y = y + 27;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 38);
        ctx.lineTo(22, 38);
        ctx.lineTo(22, 58);
        ctx.lineTo(2, 58);
        ctx.lineTo(2, 38);
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL:
      // PROTESIS_TOTAL - 29 - L
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL_IMPRESION_CUBETA:
      // PROTESIS_TOTAL - 29 - L
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL_IMPRESION_DEFINITIVA:
      // PROTESIS_TOTAL - 29 - L
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL_PRUEBA_RODETE:
      // PROTESIS_TOTAL - 29 - L
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#e7ed2d';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL_PRUEBA_ENFILADO:
      // PROTESIS_TOTAL - 29 - L
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#00ff09';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL_ENTREGA_TRABAJO:
      // PROTESIS_TOTAL - 29 - L
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_TOTAL_MAL:
      if (type === 1) {
        y = y + 73;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 3);
        ctx.lineTo(37, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PULPAR:
      // PULPAR - 28 - K
      if (type === 0) {
        x = x + 5;
        y = y + 0;
      } else {
        x = x + 5;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PERNO_FIBRA_TALLADO_PERNO:
      // PULPAR - 28 - K
      if (type === 0) {
        x = x + 5;
        y = y + 0;
      } else {
        x = x + 5;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PERNO_FIBRA_CEMENTACION:
      // PULPAR - 28 - K
      if (type === 0) {
        x = x + 5;
        y = y + 0;
      } else {
        x = x + 5;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PULPAR_INICIO:
      if (type === 0) {
        x = x + 5;
        y = y + 0;
      } else {
        x = x + 5;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.SUPER_NUMERARIO:
      // SUPER_NUMERARIO - 27 - J
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(26, 3, 32, 9, 32, 16);
        ctx.bezierCurveTo(32, 22, 26, 28, 20, 28);
        ctx.bezierCurveTo(13, 28, 7, 22, 7, 16);
        ctx.bezierCurveTo(7, 9, 13, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.TRANSPOSICION_LEFT:
      // TRANSPOSICION_LEFT - 25 - H
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.bezierCurveTo(10, 6, 25, 11, 32, 13);
        ctx.bezierCurveTo(26, 16, 13, 21, 7, 23);
        ctx.bezierCurveTo(13, 26, 26, 31, 32, 33);
        ctx.bezierCurveTo(26, 36, 13, 41, 7, 43);
        ctx.bezierCurveTo(11, 44, 18, 47, 22, 48);
        ctx.bezierCurveTo(22, 49, 22, 52, 22, 53);
        ctx.moveTo(7, 48);
        ctx.lineTo(22, 53);
        ctx.moveTo(32, 48);
        ctx.lineTo(22, 53);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_EN_ERUPCION:
      // DIENTE_EN_ERUPCION - 24 - G
      if (type === 0) {
        x = x + 38;
        y = y + 55;
      } else {
        y = y + 30;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.bezierCurveTo(10, 6, 25, 11, 32, 13);
        ctx.bezierCurveTo(26, 16, 13, 21, 7, 23);
        ctx.bezierCurveTo(13, 26, 26, 31, 32, 33);
        ctx.bezierCurveTo(26, 36, 13, 41, 7, 43);
        ctx.bezierCurveTo(11, 44, 18, 47, 22, 48);
        ctx.bezierCurveTo(22, 49, 22, 52, 22, 53);
        ctx.moveTo(7, 48);
        ctx.lineTo(22, 53);
        ctx.moveTo(32, 48);
        ctx.lineTo(22, 53);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.ORTONDICO_REMOVIBLE:
      // ORTONDICO_REMOVIBLE - 23 - F
      if (type === 0) {
        x = x + 38;
        y = y + 95;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 13);
        ctx.lineTo(17, 3);
        ctx.moveTo(17, 3);
        ctx.lineTo(32, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_INTRUIDO:
      // DIENTE_INTRUIDO - 20 - A
      if (type === 0) {
        x = x + 25;
        y = y + 95;
      } else {
        x = x + 15;
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(7, 3);
        ctx.lineTo(2, 8);
        ctx.lineTo(12, 8);
        ctx.lineTo(7, 3);
        ctx.fillStyle = '#0000ff';
        ctx.fill();
        ctx.moveTo(7, 8);
        ctx.lineTo(7, 18);
        ctx.stroke();
        ctx.closePath();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIASTEMA:
      // MICRODONCIA - 18 - O
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 6, 37, 11);
        ctx.bezierCurveTo(37, 15, 29, 18, 20, 18);
        ctx.bezierCurveTo(10, 18, 2, 15, 2, 11);
        ctx.bezierCurveTo(2, 6, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.REMANENTE_RADICULAR:
      // REMANENTE_RADICULAR - 16 - U
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 6, 37, 11);
        ctx.bezierCurveTo(37, 15, 29, 18, 20, 18);
        ctx.bezierCurveTo(10, 18, 2, 15, 2, 11);
        ctx.bezierCurveTo(2, 6, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.FUSION:
      // FUSION - 15 - Y
      if (type === 0) {
        y = y - 59;
      } else {
        y = y + 121;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(20, 3);
        ctx.bezierCurveTo(29, 3, 37, 6, 37, 11);
        ctx.bezierCurveTo(37, 15, 29, 18, 20, 18);
        ctx.bezierCurveTo(10, 18, 2, 15, 2, 11);
        ctx.bezierCurveTo(2, 6, 10, 3, 20, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.GIROVERSION:
      // GIROVERSION - 14 - T
      if (type === 0) {
        x = x + 40;
        y = y + 90;
      } else {
        y = y - 5;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 18);
        ctx.bezierCurveTo(15, -7, 30, 4, 37, 18);
        ctx.moveTo(37, 18);
        ctx.lineTo(32, 18);
        ctx.moveTo(37, 18);
        ctx.lineTo(37, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.MIGRACION:
      // MIGRACION - 13 - R
      if (type === 0) {
        x = x + 40;
        y = y + 85;
      } else {
        y = y - 5;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(7, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(7, 8);
        ctx.lineTo(12, 13);
        ctx.moveTo(7, 8);
        ctx.lineTo(12, 3);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE:
      // PROTESIS_REMOVIBLE - 12
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE_IMPRESION_CUBETA:
      // PROTESIS_REMOVIBLE - 12
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE_IMPRESION_DEFINITIVA:
      // PROTESIS_REMOVIBLE - 12
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#FF0000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE_PRUEBA_RODETE:
      // PROTESIS_REMOVIBLE - 12
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#e7ed2d';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE_PRUEBA_ENFILADO:
      // PROTESIS_REMOVIBLE - 12
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#00ff09';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE_ENTREGA_TRABAJO:
      // PROTESIS_REMOVIBLE - 12
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_REMOVIBLE_MAL:
      if (type === 0) {
        y = y + 100;
        x = x + 35;
      } else {
        y = y - 10;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(-3, 8);
        ctx.lineTo(37, 8);
        ctx.moveTo(-3, 18);
        ctx.lineTo(37, 18);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_EN_CLAVIJA:
      // DIENTE_EN_CLAVIJA - 10
      if (type === 0) {
        x = x + 48;
        y = y - 24;
      } else {
        x = x - 6;
        y = y + 110;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(27, 8);
        ctx.lineTo(7, 38);
        ctx.moveTo(27, 8);
        ctx.lineTo(47, 38);
        ctx.moveTo(7, 38);
        ctx.lineTo(47, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CURACION:
      draw = (ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(27, 8);
        ctx.lineTo(7, 38);
        ctx.lineTo(47, 38);
        ctx.lineTo(47, 38);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.ORTODONTICO_FIJO:
      if (type === 0) {
        if (breakStart === point) {
          x = x + 5;
        } else if (breakEnd === point) {
          x = x - 5;
        }
        y = y + 75;
      } else {
        if (breakStart === point) {
          x = x + 5;
        } else if (breakEnd === point) {
          x = x - 5;
        }
      }
      y = y - 5;
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        if (breakStart === point && breakEnd === point) {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(2, 3);
          ctx.lineTo(22, 3);
          ctx.lineTo(22, 23);
          ctx.lineTo(2, 23);
          ctx.lineTo(2, 3);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(12, 18);
          ctx.lineTo(12, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(17, 13);
          ctx.lineTo(7, 13);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(2, 3);
          ctx.lineTo(22, 3);
          ctx.lineTo(22, 23);
          ctx.lineTo(2, 23);
          ctx.lineTo(2, 3);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(12, 18);
          ctx.lineTo(12, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(17, 13);
          ctx.lineTo(7, 13);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(22, 13);
          ctx.lineTo(32, 13);
          ctx.stroke();
        } else if (breakEnd === point) {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(12, 3);
          ctx.lineTo(32, 3);
          ctx.lineTo(32, 23);
          ctx.lineTo(12, 23);
          ctx.lineTo(12, 3);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(2, 13);
          ctx.lineTo(12, 13);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(22, 8);
          ctx.lineTo(22, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(17, 13);
          ctx.lineTo(27, 13);
          ctx.stroke();
        } else {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(-3, 13);
          ctx.lineTo(35, 13);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.ORTODONTICO_FIJO_MAL:
      if (type === 0) {
        if (breakStart === point) {
          x = x + 5;
        } else if (breakEnd === point) {
          x = x - 5;
        }
        y = y + 75;
      } else {
        if (breakStart === point) {
          x = x + 5;
        } else if (breakEnd === point) {
          x = x - 5;
        }
      }
      y = y - 5;
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        if (breakStart === point && breakEnd === point) {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(2, 3);
          ctx.lineTo(22, 3);
          ctx.lineTo(22, 23);
          ctx.lineTo(2, 23);
          ctx.lineTo(2, 3);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(12, 18);
          ctx.lineTo(12, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(17, 13);
          ctx.lineTo(7, 13);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(2, 3);
          ctx.lineTo(22, 3);
          ctx.lineTo(22, 23);
          ctx.lineTo(2, 23);
          ctx.lineTo(2, 3);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(12, 18);
          ctx.lineTo(12, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(17, 13);
          ctx.lineTo(7, 13);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(22, 13);
          ctx.lineTo(32, 13);
          ctx.stroke();
        } else if (breakEnd === point) {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(12, 3);
          ctx.lineTo(32, 3);
          ctx.lineTo(32, 23);
          ctx.lineTo(12, 23);
          ctx.lineTo(12, 3);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(2, 13);
          ctx.lineTo(12, 13);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(22, 8);
          ctx.lineTo(22, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(17, 13);
          ctx.lineTo(27, 13);
          ctx.stroke();
        } else {
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(-3, 13);
          ctx.lineTo(35, 13);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROFILAXIS:
      if (type === 0) {
        x = x + 41;
        y = y + 85;
      } else {
        x = x - 3;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(2, 13);
        ctx.moveTo(12, 3);
        ctx.lineTo(22, 13);
        ctx.moveTo(22, 13);
        ctx.lineTo(32, 3);
        ctx.moveTo(32, 3);
        ctx.lineTo(42, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROFILAXIS_1RA_ULTRASONIDO:
      if (type === 0) {
        x = x + 41;
        y = y + 85;
      } else {
        x = x - 3;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(2, 13);
        ctx.moveTo(12, 3);
        ctx.lineTo(22, 13);
        ctx.moveTo(22, 13);
        ctx.lineTo(32, 3);
        ctx.moveTo(32, 3);
        ctx.lineTo(42, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROFILAXIS_2RA_CURETAJE:
      if (type === 0) {
        x = x + 41;
        y = y + 85;
      } else {
        x = x - 3;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#e7ed2d';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(2, 13);
        ctx.moveTo(12, 3);
        ctx.lineTo(22, 13);
        ctx.moveTo(22, 13);
        ctx.lineTo(32, 3);
        ctx.moveTo(32, 3);
        ctx.lineTo(42, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROFILAXIS_3RA_PULIDO:
      if (type === 0) {
        x = x + 41;
        y = y + 85;
      } else {
        x = x - 3;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(2, 13);
        ctx.moveTo(12, 3);
        ctx.lineTo(22, 13);
        ctx.moveTo(22, 13);
        ctx.lineTo(32, 3);
        ctx.moveTo(32, 3);
        ctx.lineTo(42, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROFILAXIS_MAL:
      if (type === 0) {
        x = x + 41;
        y = y + 85;
      } else {
        x = x - 3;
      }
      draw = (ctx, shape) => {
        if (type === 0) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(12, 3);
        ctx.lineTo(2, 13);
        ctx.moveTo(12, 3);
        ctx.lineTo(22, 13);
        ctx.moveTo(22, 13);
        ctx.lineTo(32, 3);
        ctx.moveTo(32, 3);
        ctx.lineTo(42, 13);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_FIJA:
      if (type === 0) {
        x = x - 2;
        y = y - 9;
      } else {
        x = x + 38;
        y = y + 95;
      }
      draw = (ctx, shape) => {
        if (type === 1) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 2;
        if (breakStart === point && breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(0, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(38, 8);
          ctx.lineTo(38, 18);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          }
        } else if (breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          }
        } else {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PROTESIS_FIJA_MAL:
      if (type === 0) {
        x = x - 2;
        y = y - 9;
      } else {
        x = x + 38;
        y = y + 95;
      }
      draw = (ctx, shape) => {
        if (type === 1) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        if (breakStart === point && breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(0, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(38, 8);
          ctx.lineTo(38, 18);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          }
        } else if (breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          }
        } else {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PUENTE_TALLADO:
      if (type === 0) {
        x = x - 2;
        y = y - 9;
      } else {
        x = x + 38;
        y = y + 95;
      }
      draw = (ctx, shape) => {
        if (type === 1) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 2;
        if (breakStart === point && breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(0, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(38, 8);
          ctx.lineTo(38, 18);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          }
        } else if (breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          }
        } else {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PUENTE_PROVISIONAL:
      if (type === 0) {
        x = x - 2;
        y = y - 9;
      } else {
        x = x + 38;
        y = y + 95;
      }
      draw = (ctx, shape) => {
        if (type === 1) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#e7ed2d';
        ctx.lineWidth = 2;
        if (breakStart === point && breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(0, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(38, 8);
          ctx.lineTo(38, 18);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          }
        } else if (breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          }
        } else {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.PUENTE_CEMENTACION:
      if (type === 0) {
        x = x - 2;
        y = y - 9;
      } else {
        x = x + 38;
        y = y + 95;
      }
      draw = (ctx, shape) => {
        if (type === 1) {
          ctx.rotate((180 * Math.PI) / 180);
        }
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        if (breakStart === point && breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(0, 18);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(38, 8);
          ctx.lineTo(38, 18);
          ctx.stroke();
        } else if (breakStart === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          }
        } else if (breakEnd === point) {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
          if (type === 0) {
            ctx.beginPath();
            ctx.moveTo(38, 8);
            ctx.lineTo(38, 18);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(0, 8);
            ctx.lineTo(0, 18);
            ctx.stroke();
          }
        } else {
          ctx.beginPath();
          ctx.moveTo(0, 8);
          ctx.lineTo(38, 8);
          ctx.stroke();
        }
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.ENDODONCIA_INICIO:
      switch (point) {
        case 18:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 43);
            ctx.bezierCurveTo(NaN, NaN, 7, 41, 11, 40);
            ctx.bezierCurveTo(15, 41, 19, 42, 21, 43);
            ctx.bezierCurveTo(21, 40, 20, 35, 19, 32);
            ctx.bezierCurveTo(19, 29, 16, 23, 16, 20);
            ctx.bezierCurveTo(15, 18, 13, 14, 12, 11);
            ctx.bezierCurveTo(10, 9, 9, 8, 9, 7);
            ctx.bezierCurveTo(8, 6, 8, 4, 8, 2);
            ctx.bezierCurveTo(7, 1, 6, 0, 5, 0);
            ctx.bezierCurveTo(4, 0, 3, 1, 2, 2);
            ctx.bezierCurveTo(1, 4, 1, 5, 1, 7);
            ctx.bezierCurveTo(1, 11, 2, 14, 2, 17);
            ctx.bezierCurveTo(2, 20, 3, 25, 3, 28);
            ctx.bezierCurveTo(3, 30, 3, 35, 3, 37);
            ctx.bezierCurveTo(2, 39, NaN, NaN, 2, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 17:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.beginPath();
            ctx.moveTo(11, 39);
            ctx.bezierCurveTo(15, 39, 20, 39, 24, 39);
            ctx.bezierCurveTo(24, 37, 24, 35, 23, 33);
            ctx.bezierCurveTo(23, 31, 22, 30, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 22, 20, 19);
            ctx.bezierCurveTo(20, 16, 16, 11, 15, 8);
            ctx.bezierCurveTo(14, 7, 14, 7, 13, 7);
            ctx.bezierCurveTo(12, 7, 12, 8, 12, 9);
            ctx.bezierCurveTo(12, 9, 12, 11, 12, 12);
            ctx.bezierCurveTo(12, 13, 12, 15, 12, 16);
            ctx.bezierCurveTo(11, 18, 10, 21, 10, 22);
            ctx.bezierCurveTo(10, 21, 9, 17, 9, 15);
            ctx.bezierCurveTo(8, 14, 8, 11, 7, 10);
            ctx.bezierCurveTo(7, 9, 6, 8, 5, 7);
            ctx.bezierCurveTo(5, 7, 4, 6, 3, 6);
            ctx.bezierCurveTo(2, 7, 3, 7, 2, 8);
            ctx.bezierCurveTo(2, 10, 3, 11, 3, 13);
            ctx.bezierCurveTo(3, 14, 4, 15, 4, 17);
            ctx.bezierCurveTo(4, 20, 4, 29, 3, 33);
            ctx.bezierCurveTo(3, 35, 3, 35, 3, 40);
            ctx.lineTo(7, 39);
            ctx.lineTo(11, 39);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(11, 9);
            ctx.bezierCurveTo(11, 9, 10, 8, 10, 7);
            ctx.bezierCurveTo(9, 7, 8, 8, 7, 8);
            ctx.bezierCurveTo(7, 9, 8, 10, 8, 11);
            ctx.bezierCurveTo(8, 12, 9, 13, 9, 14);
            ctx.bezierCurveTo(9, 16, 10, 19, 10, 21);
            ctx.bezierCurveTo(11, 19, 12, 16, 12, 14);
            ctx.bezierCurveTo(12, 13, 11, 10, 11, 9);
            ctx.bezierCurveTo(11, 9, 11, 9, 11, 9);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 16:
          x = x + 8;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, 40);
            ctx.bezierCurveTo(6, 40, 16, 40, 23, 40);
            ctx.bezierCurveTo(23, 36, 22, 37, 22, 34);
            ctx.bezierCurveTo(23, 30, 24, 29, 24, 26);
            ctx.bezierCurveTo(24, 22, 23, 19, 23, 17);
            ctx.bezierCurveTo(23, 14, 21, 8, 20, 4);
            ctx.bezierCurveTo(20, 2, 19, 1, 18, 1);
            ctx.bezierCurveTo(18, 0, 18, 0, 16, 0);
            ctx.bezierCurveTo(16, 2, 16, 3, 16, 3);
            ctx.bezierCurveTo(16, 5, 16, 8, 16, 10);
            ctx.bezierCurveTo(16, 12, 16, 17, 16, 19);
            ctx.bezierCurveTo(15, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(12, 21, 11, 16, 11, 14);
            ctx.bezierCurveTo(10, 11, 9, 5, 8, 3);
            ctx.bezierCurveTo(7, 1, 7, 2, 7, 2);
            ctx.bezierCurveTo(5, 2, 4, 2, 4, 3);
            ctx.bezierCurveTo(4, 5, 4, 10, 4, 13);
            ctx.bezierCurveTo(5, 14, 5, 17, 5, 19);
            ctx.bezierCurveTo(5, 20, 5, 20, 5, 23);
            ctx.bezierCurveTo(4, 26, 4, 30, 3, 32);
            ctx.bezierCurveTo(2, 34, 1, 38, 0, 40);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(16, 5);
            ctx.bezierCurveTo(15, 4, 14, 3, 13, 2);
            ctx.bezierCurveTo(12, 2, 11, 3, 10, 3);
            ctx.bezierCurveTo(10, 4, 9, 5, 9, 6);
            ctx.bezierCurveTo(10, 8, 11, 11, 11, 13);
            ctx.bezierCurveTo(11, 15, 12, 18, 12, 19);
            ctx.bezierCurveTo(12, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(14, 22, 15, 21, 16, 20);
            ctx.bezierCurveTo(16, 18, 16, 13, 16, 10);
            ctx.bezierCurveTo(16, 9, 16, 6, 16, 5);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 15:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 43);
            ctx.bezierCurveTo(10, 43, 13, 42, 16, 42);
            ctx.bezierCurveTo(20, 42, 22, 43, 23, 44);
            ctx.bezierCurveTo(23, 42, 23, 39, 23, 38);
            ctx.bezierCurveTo(23, 35, 23, 31, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 19, 19, 16);
            ctx.bezierCurveTo(18, 13, 16, 7, 15, 3);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 4, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 10, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 8, 31);
            ctx.bezierCurveTo(7, 35, 8, 40, 8, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 14:
          x = x + 2;
          y = y + 7;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 48);
            ctx.bezierCurveTo(10, 48, 13, 46, 16, 46);
            ctx.bezierCurveTo(20, 47, 22, 48, 23, 49);
            ctx.bezierCurveTo(23, 47, 23, 44, 23, 42);
            ctx.bezierCurveTo(23, 39, 23, 35, 22, 31);
            ctx.bezierCurveTo(22, 28, 20, 21, 19, 18);
            ctx.bezierCurveTo(18, 14, 16, 7, 15, 4);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 5, 10, 9, 10, 11);
            ctx.bezierCurveTo(10, 14, 9, 19, 10, 23);
            ctx.bezierCurveTo(9, 26, 8, 30, 8, 35);
            ctx.bezierCurveTo(7, 39, 8, 45, 8, 48);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 13:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 12:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 11:
          x = x + 4;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 21:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 22:
          x = x + 7;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 23:
          x = x + 7;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 24:
          x = x + 9;
          y = y + 4;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 25:
          x = x + 12;
          y = y + 14;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 41);
            ctx.bezierCurveTo(3, 41, 8, 40, 10, 40);
            ctx.bezierCurveTo(12, 40, 14, 41, 15, 42);
            ctx.bezierCurveTo(15, 40, 14, 37, 14, 36);
            ctx.bezierCurveTo(14, 34, 14, 31, 14, 28);
            ctx.bezierCurveTo(13, 25, 12, 20, 12, 18);
            ctx.bezierCurveTo(11, 15, 13, 11, 13, 7);
            ctx.bezierCurveTo(13, 7, 13, 5, 12, 5);
            ctx.bezierCurveTo(11, 4, 10, 5, 10, 6);
            ctx.bezierCurveTo(9, 7, 7, 8, 7, 10);
            ctx.bezierCurveTo(6, 13, 4, 18, 4, 23);
            ctx.bezierCurveTo(4, 26, 3, 27, 3, 31);
            ctx.bezierCurveTo(2, 33, 1, 37, 2, 41);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 26:
          x = x + 0;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 27:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 28:
          x = x + 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(10, 41);
            ctx.bezierCurveTo(12, 41, 17, 41, 20, 41);
            ctx.bezierCurveTo(21, 41, 26, 42, 27, 43);
            ctx.bezierCurveTo(27, 40, 27, 35, 27, 33);
            ctx.bezierCurveTo(27, 32, 28, 27, 28, 24);
            ctx.bezierCurveTo(29, 22, 30, 19, 31, 17);
            ctx.bezierCurveTo(31, 15, 32, 13, 32, 12);
            ctx.bezierCurveTo(32, 10, 31, 9, 29, 7);
            ctx.bezierCurveTo(27, 7, 27, 7, 26, 8);
            ctx.bezierCurveTo(25, 9, 23, 12, 22, 13);
            ctx.bezierCurveTo(21, 15, 17, 19, 16, 22);
            ctx.bezierCurveTo(15, 26, 12, 31, 11, 34);
            ctx.bezierCurveTo(10, 36, 10, 39, 10, 41);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 55:
          x = x + 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 44);
            ctx.bezierCurveTo(10, 44, 23, 44, 29, 44);
            ctx.bezierCurveTo(28, 42, 27, 38, 27, 35);
            ctx.bezierCurveTo(28, 33, 28, 27, 29, 24);
            ctx.bezierCurveTo(28, 21, 28, 15, 26, 12);
            ctx.bezierCurveTo(25, 10, 24, 6, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 13, 18, 21, 18, 25);
            ctx.bezierCurveTo(17, 26, 16, 29, 16, 30);
            ctx.bezierCurveTo(16, 28, 15, 24, 14, 20);
            ctx.bezierCurveTo(14, 16, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 6, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 10);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 18, 5, 23, 5, 25);
            ctx.bezierCurveTo(5, 28, 5, 32, 5, 34);
            ctx.bezierCurveTo(6, 36, 5, 39, 5, 41);
            ctx.bezierCurveTo(4, 42, 4, 43, 4, 44);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 54:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 46);
            ctx.bezierCurveTo(10, 46, 23, 46, 29, 46);
            ctx.bezierCurveTo(28, 44, 27, 40, 27, 37);
            ctx.bezierCurveTo(28, 34, 28, 28, 29, 25);
            ctx.bezierCurveTo(28, 21, 28, 16, 26, 13);
            ctx.bezierCurveTo(25, 10, 24, 7, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 14, 18, 22, 18, 26);
            ctx.bezierCurveTo(17, 27, 16, 30, 16, 32);
            ctx.bezierCurveTo(16, 29, 15, 25, 14, 21);
            ctx.bezierCurveTo(14, 17, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 7, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 11);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 19, 5, 24, 5, 26);
            ctx.bezierCurveTo(5, 29, 5, 33, 5, 36);
            ctx.bezierCurveTo(6, 38, 5, 41, 5, 43);
            ctx.bezierCurveTo(4, 44, 4, 45, 4, 46);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 53:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 52:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 51:
          x = x + 2;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 61:
          x = x + 6;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 62:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 63:
          x = x + 5;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 64:
          x = x + 1;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 65:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#b50000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 48:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 12);
            ctx.bezierCurveTo(8, 13, 10, 12, 13, 13);
            ctx.bezierCurveTo(17, 12, 20, 12, 23, 12);
            ctx.bezierCurveTo(23, 16, 23, 22, 22, 26);
            ctx.bezierCurveTo(21, 30, 18, 35, 16, 38);
            ctx.bezierCurveTo(15, 40, 12, 43, 11, 43);
            ctx.bezierCurveTo(9, 42, 9, 42, 9, 41);
            ctx.bezierCurveTo(10, 39, 11, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 13, 30, 14, 28);
            ctx.bezierCurveTo(14, 27, 14, 23, 14, 21);
            ctx.bezierCurveTo(14, 23, 13, 28, 13, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 11, 35);
            ctx.bezierCurveTo(11, 36, 9, 39, 9, 40);
            ctx.bezierCurveTo(8, 40, 6, 41, 5, 42);
            ctx.bezierCurveTo(5, 42, 4, 43, 3, 42);
            ctx.bezierCurveTo(2, 42, 2, 42, 1, 40);
            ctx.bezierCurveTo(2, 38, 2, 37, 2, 37);
            ctx.bezierCurveTo(3, 36, 3, 36, 4, 35);
            ctx.bezierCurveTo(4, 34, 6, 31, 6, 30);
            ctx.bezierCurveTo(6, 29, 6, 28, 7, 25);
            ctx.bezierCurveTo(6, 23, 7, 20, 7, 16);
            ctx.bezierCurveTo(7, 14, 6, 14, 6, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 47:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 46:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 45:
          x = x + 11;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 0);
            ctx.bezierCurveTo(3, 0, 7, 1, 9, 1);
            ctx.bezierCurveTo(11, 1, 14, 0, 16, 0);
            ctx.bezierCurveTo(16, 7, 15, 20, 14, 27);
            ctx.bezierCurveTo(14, 31, 13, 37, 12, 41);
            ctx.bezierCurveTo(11, 43, 7, 46, 5, 47);
            ctx.bezierCurveTo(4, 47, 3, 47, 3, 45);
            ctx.bezierCurveTo(3, 43, 4, 40, 5, 37);
            ctx.bezierCurveTo(5, 34, 3, 23, 4, 19);
            ctx.bezierCurveTo(3, 18, 1, 5, 0, 0);
            ctx.bezierCurveTo(1, 0, 0, 0, 1, 0);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 44:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(3, 3);
            ctx.bezierCurveTo(5, 3, 7, 5, 9, 5);
            ctx.bezierCurveTo(11, 5, 14, 3, 16, 3);
            ctx.bezierCurveTo(17, 14, 13, 34, 11, 45);
            ctx.bezierCurveTo(10, 46, 9, 46, 8, 45);
            ctx.bezierCurveTo(5, 34, 3, 14, 3, 3);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 43:
          x = x + 3;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 42:
          x = x + 13;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 41:
          x = x + 11;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 38:
          x = x + 11;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(18, 12);
            ctx.bezierCurveTo(16, 13, 14, 12, 11, 13);
            ctx.bezierCurveTo(7, 12, 4, 12, 1, 12);
            ctx.bezierCurveTo(1, 16, 1, 22, 2, 26);
            ctx.bezierCurveTo(3, 30, 6, 35, 8, 38);
            ctx.bezierCurveTo(9, 40, 12, 43, 13, 43);
            ctx.bezierCurveTo(15, 42, 15, 42, 15, 41);
            ctx.bezierCurveTo(14, 39, 13, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 11, 30, 10, 28);
            ctx.bezierCurveTo(10, 27, 10, 23, 10, 21);
            ctx.bezierCurveTo(10, 23, 11, 28, 11, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 13, 35);
            ctx.bezierCurveTo(13, 36, 15, 39, 15, 40);
            ctx.bezierCurveTo(16, 40, 18, 41, 19, 42);
            ctx.bezierCurveTo(19, 42, 20, 43, 21, 42);
            ctx.bezierCurveTo(22, 42, 22, 42, 23, 40);
            ctx.bezierCurveTo(22, 38, 22, 37, 22, 37);
            ctx.bezierCurveTo(21, 36, 21, 36, 20, 35);
            ctx.bezierCurveTo(20, 34, 18, 31, 18, 30);
            ctx.bezierCurveTo(18, 29, 18, 28, 17, 25);
            ctx.bezierCurveTo(18, 23, 17, 20, 17, 16);
            ctx.bezierCurveTo(17, 14, 18, 14, 18, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 37:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 36:
          x = x + 6;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 35:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(15, 0);
            ctx.bezierCurveTo(13, 0, 9, 1, 7, 1);
            ctx.bezierCurveTo(5, 1, 2, 0, 0, 0);
            ctx.bezierCurveTo(0, 7, 1, 20, 2, 27);
            ctx.bezierCurveTo(2, 31, 3, 37, 4, 41);
            ctx.bezierCurveTo(5, 43, 9, 46, 11, 47);
            ctx.bezierCurveTo(12, 47, 13, 47, 13, 45);
            ctx.bezierCurveTo(13, 43, 12, 40, 11, 37);
            ctx.bezierCurveTo(11, 34, 13, 23, 12, 19);
            ctx.bezierCurveTo(13, 18, 15, 5, 16, 0);
            ctx.bezierCurveTo(15, 0, 16, 0, 15, 0);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 34:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(16, 3);
            ctx.bezierCurveTo(14, 3, 12, 5, 10, 5);
            ctx.bezierCurveTo(8, 5, 5, 3, 3, 3);
            ctx.bezierCurveTo(2, 14, 6, 34, 8, 45);
            ctx.bezierCurveTo(9, 46, 10, 46, 11, 45);
            ctx.bezierCurveTo(14, 34, 16, 14, 16, 3);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 33:
          x = x - 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 32:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 31:
          x = x + 14;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 85:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 84:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 83:
          x = x + 4;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 82:
          x = x + 12;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 81:
          x = x + 7;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 71:
          x = x + 15;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 72:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 73:
          x = x - 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 74:
          x = x + 7;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 75:
          x = x + 7;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        default:
          break;
      }
      break;
    case all.ENDODONCIA_FIN:
      switch (point) {
        case 18:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 43);
            ctx.bezierCurveTo(NaN, NaN, 7, 41, 11, 40);
            ctx.bezierCurveTo(15, 41, 19, 42, 21, 43);
            ctx.bezierCurveTo(21, 40, 20, 35, 19, 32);
            ctx.bezierCurveTo(19, 29, 16, 23, 16, 20);
            ctx.bezierCurveTo(15, 18, 13, 14, 12, 11);
            ctx.bezierCurveTo(10, 9, 9, 8, 9, 7);
            ctx.bezierCurveTo(8, 6, 8, 4, 8, 2);
            ctx.bezierCurveTo(7, 1, 6, 0, 5, 0);
            ctx.bezierCurveTo(4, 0, 3, 1, 2, 2);
            ctx.bezierCurveTo(1, 4, 1, 5, 1, 7);
            ctx.bezierCurveTo(1, 11, 2, 14, 2, 17);
            ctx.bezierCurveTo(2, 20, 3, 25, 3, 28);
            ctx.bezierCurveTo(3, 30, 3, 35, 3, 37);
            ctx.bezierCurveTo(2, 39, NaN, NaN, 2, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 17:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.beginPath();
            ctx.moveTo(11, 39);
            ctx.bezierCurveTo(15, 39, 20, 39, 24, 39);
            ctx.bezierCurveTo(24, 37, 24, 35, 23, 33);
            ctx.bezierCurveTo(23, 31, 22, 30, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 22, 20, 19);
            ctx.bezierCurveTo(20, 16, 16, 11, 15, 8);
            ctx.bezierCurveTo(14, 7, 14, 7, 13, 7);
            ctx.bezierCurveTo(12, 7, 12, 8, 12, 9);
            ctx.bezierCurveTo(12, 9, 12, 11, 12, 12);
            ctx.bezierCurveTo(12, 13, 12, 15, 12, 16);
            ctx.bezierCurveTo(11, 18, 10, 21, 10, 22);
            ctx.bezierCurveTo(10, 21, 9, 17, 9, 15);
            ctx.bezierCurveTo(8, 14, 8, 11, 7, 10);
            ctx.bezierCurveTo(7, 9, 6, 8, 5, 7);
            ctx.bezierCurveTo(5, 7, 4, 6, 3, 6);
            ctx.bezierCurveTo(2, 7, 3, 7, 2, 8);
            ctx.bezierCurveTo(2, 10, 3, 11, 3, 13);
            ctx.bezierCurveTo(3, 14, 4, 15, 4, 17);
            ctx.bezierCurveTo(4, 20, 4, 29, 3, 33);
            ctx.bezierCurveTo(3, 35, 3, 35, 3, 40);
            ctx.lineTo(7, 39);
            ctx.lineTo(11, 39);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(11, 9);
            ctx.bezierCurveTo(11, 9, 10, 8, 10, 7);
            ctx.bezierCurveTo(9, 7, 8, 8, 7, 8);
            ctx.bezierCurveTo(7, 9, 8, 10, 8, 11);
            ctx.bezierCurveTo(8, 12, 9, 13, 9, 14);
            ctx.bezierCurveTo(9, 16, 10, 19, 10, 21);
            ctx.bezierCurveTo(11, 19, 12, 16, 12, 14);
            ctx.bezierCurveTo(12, 13, 11, 10, 11, 9);
            ctx.bezierCurveTo(11, 9, 11, 9, 11, 9);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 16:
          x = x + 8;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, 40);
            ctx.bezierCurveTo(6, 40, 16, 40, 23, 40);
            ctx.bezierCurveTo(23, 36, 22, 37, 22, 34);
            ctx.bezierCurveTo(23, 30, 24, 29, 24, 26);
            ctx.bezierCurveTo(24, 22, 23, 19, 23, 17);
            ctx.bezierCurveTo(23, 14, 21, 8, 20, 4);
            ctx.bezierCurveTo(20, 2, 19, 1, 18, 1);
            ctx.bezierCurveTo(18, 0, 18, 0, 16, 0);
            ctx.bezierCurveTo(16, 2, 16, 3, 16, 3);
            ctx.bezierCurveTo(16, 5, 16, 8, 16, 10);
            ctx.bezierCurveTo(16, 12, 16, 17, 16, 19);
            ctx.bezierCurveTo(15, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(12, 21, 11, 16, 11, 14);
            ctx.bezierCurveTo(10, 11, 9, 5, 8, 3);
            ctx.bezierCurveTo(7, 1, 7, 2, 7, 2);
            ctx.bezierCurveTo(5, 2, 4, 2, 4, 3);
            ctx.bezierCurveTo(4, 5, 4, 10, 4, 13);
            ctx.bezierCurveTo(5, 14, 5, 17, 5, 19);
            ctx.bezierCurveTo(5, 20, 5, 20, 5, 23);
            ctx.bezierCurveTo(4, 26, 4, 30, 3, 32);
            ctx.bezierCurveTo(2, 34, 1, 38, 0, 40);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(16, 5);
            ctx.bezierCurveTo(15, 4, 14, 3, 13, 2);
            ctx.bezierCurveTo(12, 2, 11, 3, 10, 3);
            ctx.bezierCurveTo(10, 4, 9, 5, 9, 6);
            ctx.bezierCurveTo(10, 8, 11, 11, 11, 13);
            ctx.bezierCurveTo(11, 15, 12, 18, 12, 19);
            ctx.bezierCurveTo(12, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(14, 22, 15, 21, 16, 20);
            ctx.bezierCurveTo(16, 18, 16, 13, 16, 10);
            ctx.bezierCurveTo(16, 9, 16, 6, 16, 5);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 15:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 43);
            ctx.bezierCurveTo(10, 43, 13, 42, 16, 42);
            ctx.bezierCurveTo(20, 42, 22, 43, 23, 44);
            ctx.bezierCurveTo(23, 42, 23, 39, 23, 38);
            ctx.bezierCurveTo(23, 35, 23, 31, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 19, 19, 16);
            ctx.bezierCurveTo(18, 13, 16, 7, 15, 3);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 4, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 10, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 8, 31);
            ctx.bezierCurveTo(7, 35, 8, 40, 8, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 14:
          x = x + 2;
          y = y + 7;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 48);
            ctx.bezierCurveTo(10, 48, 13, 46, 16, 46);
            ctx.bezierCurveTo(20, 47, 22, 48, 23, 49);
            ctx.bezierCurveTo(23, 47, 23, 44, 23, 42);
            ctx.bezierCurveTo(23, 39, 23, 35, 22, 31);
            ctx.bezierCurveTo(22, 28, 20, 21, 19, 18);
            ctx.bezierCurveTo(18, 14, 16, 7, 15, 4);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 5, 10, 9, 10, 11);
            ctx.bezierCurveTo(10, 14, 9, 19, 10, 23);
            ctx.bezierCurveTo(9, 26, 8, 30, 8, 35);
            ctx.bezierCurveTo(7, 39, 8, 45, 8, 48);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 13:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 12:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 11:
          x = x + 4;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 21:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 22:
          x = x + 7;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 23:
          x = x + 7;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 24:
          x = x + 9;
          y = y + 4;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 25:
          x = x + 12;
          y = y + 14;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 41);
            ctx.bezierCurveTo(3, 41, 8, 40, 10, 40);
            ctx.bezierCurveTo(12, 40, 14, 41, 15, 42);
            ctx.bezierCurveTo(15, 40, 14, 37, 14, 36);
            ctx.bezierCurveTo(14, 34, 14, 31, 14, 28);
            ctx.bezierCurveTo(13, 25, 12, 20, 12, 18);
            ctx.bezierCurveTo(11, 15, 13, 11, 13, 7);
            ctx.bezierCurveTo(13, 7, 13, 5, 12, 5);
            ctx.bezierCurveTo(11, 4, 10, 5, 10, 6);
            ctx.bezierCurveTo(9, 7, 7, 8, 7, 10);
            ctx.bezierCurveTo(6, 13, 4, 18, 4, 23);
            ctx.bezierCurveTo(4, 26, 3, 27, 3, 31);
            ctx.bezierCurveTo(2, 33, 1, 37, 2, 41);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 26:
          x = x + 0;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 27:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 28:
          x = x + 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(10, 41);
            ctx.bezierCurveTo(12, 41, 17, 41, 20, 41);
            ctx.bezierCurveTo(21, 41, 26, 42, 27, 43);
            ctx.bezierCurveTo(27, 40, 27, 35, 27, 33);
            ctx.bezierCurveTo(27, 32, 28, 27, 28, 24);
            ctx.bezierCurveTo(29, 22, 30, 19, 31, 17);
            ctx.bezierCurveTo(31, 15, 32, 13, 32, 12);
            ctx.bezierCurveTo(32, 10, 31, 9, 29, 7);
            ctx.bezierCurveTo(27, 7, 27, 7, 26, 8);
            ctx.bezierCurveTo(25, 9, 23, 12, 22, 13);
            ctx.bezierCurveTo(21, 15, 17, 19, 16, 22);
            ctx.bezierCurveTo(15, 26, 12, 31, 11, 34);
            ctx.bezierCurveTo(10, 36, 10, 39, 10, 41);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 55:
          x = x + 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 44);
            ctx.bezierCurveTo(10, 44, 23, 44, 29, 44);
            ctx.bezierCurveTo(28, 42, 27, 38, 27, 35);
            ctx.bezierCurveTo(28, 33, 28, 27, 29, 24);
            ctx.bezierCurveTo(28, 21, 28, 15, 26, 12);
            ctx.bezierCurveTo(25, 10, 24, 6, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 13, 18, 21, 18, 25);
            ctx.bezierCurveTo(17, 26, 16, 29, 16, 30);
            ctx.bezierCurveTo(16, 28, 15, 24, 14, 20);
            ctx.bezierCurveTo(14, 16, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 6, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 10);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 18, 5, 23, 5, 25);
            ctx.bezierCurveTo(5, 28, 5, 32, 5, 34);
            ctx.bezierCurveTo(6, 36, 5, 39, 5, 41);
            ctx.bezierCurveTo(4, 42, 4, 43, 4, 44);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 54:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 46);
            ctx.bezierCurveTo(10, 46, 23, 46, 29, 46);
            ctx.bezierCurveTo(28, 44, 27, 40, 27, 37);
            ctx.bezierCurveTo(28, 34, 28, 28, 29, 25);
            ctx.bezierCurveTo(28, 21, 28, 16, 26, 13);
            ctx.bezierCurveTo(25, 10, 24, 7, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 14, 18, 22, 18, 26);
            ctx.bezierCurveTo(17, 27, 16, 30, 16, 32);
            ctx.bezierCurveTo(16, 29, 15, 25, 14, 21);
            ctx.bezierCurveTo(14, 17, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 7, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 11);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 19, 5, 24, 5, 26);
            ctx.bezierCurveTo(5, 29, 5, 33, 5, 36);
            ctx.bezierCurveTo(6, 38, 5, 41, 5, 43);
            ctx.bezierCurveTo(4, 44, 4, 45, 4, 46);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 53:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 52:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 51:
          x = x + 2;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 61:
          x = x + 6;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 62:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 63:
          x = x + 5;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 64:
          x = x + 1;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 65:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 48:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 12);
            ctx.bezierCurveTo(8, 13, 10, 12, 13, 13);
            ctx.bezierCurveTo(17, 12, 20, 12, 23, 12);
            ctx.bezierCurveTo(23, 16, 23, 22, 22, 26);
            ctx.bezierCurveTo(21, 30, 18, 35, 16, 38);
            ctx.bezierCurveTo(15, 40, 12, 43, 11, 43);
            ctx.bezierCurveTo(9, 42, 9, 42, 9, 41);
            ctx.bezierCurveTo(10, 39, 11, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 13, 30, 14, 28);
            ctx.bezierCurveTo(14, 27, 14, 23, 14, 21);
            ctx.bezierCurveTo(14, 23, 13, 28, 13, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 11, 35);
            ctx.bezierCurveTo(11, 36, 9, 39, 9, 40);
            ctx.bezierCurveTo(8, 40, 6, 41, 5, 42);
            ctx.bezierCurveTo(5, 42, 4, 43, 3, 42);
            ctx.bezierCurveTo(2, 42, 2, 42, 1, 40);
            ctx.bezierCurveTo(2, 38, 2, 37, 2, 37);
            ctx.bezierCurveTo(3, 36, 3, 36, 4, 35);
            ctx.bezierCurveTo(4, 34, 6, 31, 6, 30);
            ctx.bezierCurveTo(6, 29, 6, 28, 7, 25);
            ctx.bezierCurveTo(6, 23, 7, 20, 7, 16);
            ctx.bezierCurveTo(7, 14, 6, 14, 6, 12);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 47:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 46:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 45:
          x = x + 11;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 0);
            ctx.bezierCurveTo(3, 0, 7, 1, 9, 1);
            ctx.bezierCurveTo(11, 1, 14, 0, 16, 0);
            ctx.bezierCurveTo(16, 7, 15, 20, 14, 27);
            ctx.bezierCurveTo(14, 31, 13, 37, 12, 41);
            ctx.bezierCurveTo(11, 43, 7, 46, 5, 47);
            ctx.bezierCurveTo(4, 47, 3, 47, 3, 45);
            ctx.bezierCurveTo(3, 43, 4, 40, 5, 37);
            ctx.bezierCurveTo(5, 34, 3, 23, 4, 19);
            ctx.bezierCurveTo(3, 18, 1, 5, 0, 0);
            ctx.bezierCurveTo(1, 0, 0, 0, 1, 0);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 44:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(3, 3);
            ctx.bezierCurveTo(5, 3, 7, 5, 9, 5);
            ctx.bezierCurveTo(11, 5, 14, 3, 16, 3);
            ctx.bezierCurveTo(17, 14, 13, 34, 11, 45);
            ctx.bezierCurveTo(10, 46, 9, 46, 8, 45);
            ctx.bezierCurveTo(5, 34, 3, 14, 3, 3);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 43:
          x = x + 3;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 42:
          x = x + 13;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 41:
          x = x + 11;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 38:
          x = x + 11;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(18, 12);
            ctx.bezierCurveTo(16, 13, 14, 12, 11, 13);
            ctx.bezierCurveTo(7, 12, 4, 12, 1, 12);
            ctx.bezierCurveTo(1, 16, 1, 22, 2, 26);
            ctx.bezierCurveTo(3, 30, 6, 35, 8, 38);
            ctx.bezierCurveTo(9, 40, 12, 43, 13, 43);
            ctx.bezierCurveTo(15, 42, 15, 42, 15, 41);
            ctx.bezierCurveTo(14, 39, 13, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 11, 30, 10, 28);
            ctx.bezierCurveTo(10, 27, 10, 23, 10, 21);
            ctx.bezierCurveTo(10, 23, 11, 28, 11, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 13, 35);
            ctx.bezierCurveTo(13, 36, 15, 39, 15, 40);
            ctx.bezierCurveTo(16, 40, 18, 41, 19, 42);
            ctx.bezierCurveTo(19, 42, 20, 43, 21, 42);
            ctx.bezierCurveTo(22, 42, 22, 42, 23, 40);
            ctx.bezierCurveTo(22, 38, 22, 37, 22, 37);
            ctx.bezierCurveTo(21, 36, 21, 36, 20, 35);
            ctx.bezierCurveTo(20, 34, 18, 31, 18, 30);
            ctx.bezierCurveTo(18, 29, 18, 28, 17, 25);
            ctx.bezierCurveTo(18, 23, 17, 20, 17, 16);
            ctx.bezierCurveTo(17, 14, 18, 14, 18, 12);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 37:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 36:
          x = x + 6;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 35:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(15, 0);
            ctx.bezierCurveTo(13, 0, 9, 1, 7, 1);
            ctx.bezierCurveTo(5, 1, 2, 0, 0, 0);
            ctx.bezierCurveTo(0, 7, 1, 20, 2, 27);
            ctx.bezierCurveTo(2, 31, 3, 37, 4, 41);
            ctx.bezierCurveTo(5, 43, 9, 46, 11, 47);
            ctx.bezierCurveTo(12, 47, 13, 47, 13, 45);
            ctx.bezierCurveTo(13, 43, 12, 40, 11, 37);
            ctx.bezierCurveTo(11, 34, 13, 23, 12, 19);
            ctx.bezierCurveTo(13, 18, 15, 5, 16, 0);
            ctx.bezierCurveTo(15, 0, 16, 0, 15, 0);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 34:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(16, 3);
            ctx.bezierCurveTo(14, 3, 12, 5, 10, 5);
            ctx.bezierCurveTo(8, 5, 5, 3, 3, 3);
            ctx.bezierCurveTo(2, 14, 6, 34, 8, 45);
            ctx.bezierCurveTo(9, 46, 10, 46, 11, 45);
            ctx.bezierCurveTo(14, 34, 16, 14, 16, 3);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 33:
          x = x - 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 32:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 31:
          x = x + 14;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 85:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 84:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 83:
          x = x + 4;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 82:
          x = x + 12;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 81:
          x = x + 7;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 71:
          x = x + 15;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 72:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 73:
          x = x - 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 74:
          x = x + 7;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 75:
          x = x + 7;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#009105';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        default:
          break;
      }
      break;
    case all.ENDODONCIA_MAL:
      switch (point) {
        case 18:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 43);
            ctx.bezierCurveTo(NaN, NaN, 7, 41, 11, 40);
            ctx.bezierCurveTo(15, 41, 19, 42, 21, 43);
            ctx.bezierCurveTo(21, 40, 20, 35, 19, 32);
            ctx.bezierCurveTo(19, 29, 16, 23, 16, 20);
            ctx.bezierCurveTo(15, 18, 13, 14, 12, 11);
            ctx.bezierCurveTo(10, 9, 9, 8, 9, 7);
            ctx.bezierCurveTo(8, 6, 8, 4, 8, 2);
            ctx.bezierCurveTo(7, 1, 6, 0, 5, 0);
            ctx.bezierCurveTo(4, 0, 3, 1, 2, 2);
            ctx.bezierCurveTo(1, 4, 1, 5, 1, 7);
            ctx.bezierCurveTo(1, 11, 2, 14, 2, 17);
            ctx.bezierCurveTo(2, 20, 3, 25, 3, 28);
            ctx.bezierCurveTo(3, 30, 3, 35, 3, 37);
            ctx.bezierCurveTo(2, 39, NaN, NaN, 2, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 17:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.beginPath();
            ctx.moveTo(11, 39);
            ctx.bezierCurveTo(15, 39, 20, 39, 24, 39);
            ctx.bezierCurveTo(24, 37, 24, 35, 23, 33);
            ctx.bezierCurveTo(23, 31, 22, 30, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 22, 20, 19);
            ctx.bezierCurveTo(20, 16, 16, 11, 15, 8);
            ctx.bezierCurveTo(14, 7, 14, 7, 13, 7);
            ctx.bezierCurveTo(12, 7, 12, 8, 12, 9);
            ctx.bezierCurveTo(12, 9, 12, 11, 12, 12);
            ctx.bezierCurveTo(12, 13, 12, 15, 12, 16);
            ctx.bezierCurveTo(11, 18, 10, 21, 10, 22);
            ctx.bezierCurveTo(10, 21, 9, 17, 9, 15);
            ctx.bezierCurveTo(8, 14, 8, 11, 7, 10);
            ctx.bezierCurveTo(7, 9, 6, 8, 5, 7);
            ctx.bezierCurveTo(5, 7, 4, 6, 3, 6);
            ctx.bezierCurveTo(2, 7, 3, 7, 2, 8);
            ctx.bezierCurveTo(2, 10, 3, 11, 3, 13);
            ctx.bezierCurveTo(3, 14, 4, 15, 4, 17);
            ctx.bezierCurveTo(4, 20, 4, 29, 3, 33);
            ctx.bezierCurveTo(3, 35, 3, 35, 3, 40);
            ctx.lineTo(7, 39);
            ctx.lineTo(11, 39);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(11, 9);
            ctx.bezierCurveTo(11, 9, 10, 8, 10, 7);
            ctx.bezierCurveTo(9, 7, 8, 8, 7, 8);
            ctx.bezierCurveTo(7, 9, 8, 10, 8, 11);
            ctx.bezierCurveTo(8, 12, 9, 13, 9, 14);
            ctx.bezierCurveTo(9, 16, 10, 19, 10, 21);
            ctx.bezierCurveTo(11, 19, 12, 16, 12, 14);
            ctx.bezierCurveTo(12, 13, 11, 10, 11, 9);
            ctx.bezierCurveTo(11, 9, 11, 9, 11, 9);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 16:
          x = x + 8;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, 40);
            ctx.bezierCurveTo(6, 40, 16, 40, 23, 40);
            ctx.bezierCurveTo(23, 36, 22, 37, 22, 34);
            ctx.bezierCurveTo(23, 30, 24, 29, 24, 26);
            ctx.bezierCurveTo(24, 22, 23, 19, 23, 17);
            ctx.bezierCurveTo(23, 14, 21, 8, 20, 4);
            ctx.bezierCurveTo(20, 2, 19, 1, 18, 1);
            ctx.bezierCurveTo(18, 0, 18, 0, 16, 0);
            ctx.bezierCurveTo(16, 2, 16, 3, 16, 3);
            ctx.bezierCurveTo(16, 5, 16, 8, 16, 10);
            ctx.bezierCurveTo(16, 12, 16, 17, 16, 19);
            ctx.bezierCurveTo(15, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(12, 21, 11, 16, 11, 14);
            ctx.bezierCurveTo(10, 11, 9, 5, 8, 3);
            ctx.bezierCurveTo(7, 1, 7, 2, 7, 2);
            ctx.bezierCurveTo(5, 2, 4, 2, 4, 3);
            ctx.bezierCurveTo(4, 5, 4, 10, 4, 13);
            ctx.bezierCurveTo(5, 14, 5, 17, 5, 19);
            ctx.bezierCurveTo(5, 20, 5, 20, 5, 23);
            ctx.bezierCurveTo(4, 26, 4, 30, 3, 32);
            ctx.bezierCurveTo(2, 34, 1, 38, 0, 40);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(16, 5);
            ctx.bezierCurveTo(15, 4, 14, 3, 13, 2);
            ctx.bezierCurveTo(12, 2, 11, 3, 10, 3);
            ctx.bezierCurveTo(10, 4, 9, 5, 9, 6);
            ctx.bezierCurveTo(10, 8, 11, 11, 11, 13);
            ctx.bezierCurveTo(11, 15, 12, 18, 12, 19);
            ctx.bezierCurveTo(12, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(14, 22, 15, 21, 16, 20);
            ctx.bezierCurveTo(16, 18, 16, 13, 16, 10);
            ctx.bezierCurveTo(16, 9, 16, 6, 16, 5);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 15:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 43);
            ctx.bezierCurveTo(10, 43, 13, 42, 16, 42);
            ctx.bezierCurveTo(20, 42, 22, 43, 23, 44);
            ctx.bezierCurveTo(23, 42, 23, 39, 23, 38);
            ctx.bezierCurveTo(23, 35, 23, 31, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 19, 19, 16);
            ctx.bezierCurveTo(18, 13, 16, 7, 15, 3);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 4, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 10, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 8, 31);
            ctx.bezierCurveTo(7, 35, 8, 40, 8, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 14:
          x = x + 2;
          y = y + 7;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 48);
            ctx.bezierCurveTo(10, 48, 13, 46, 16, 46);
            ctx.bezierCurveTo(20, 47, 22, 48, 23, 49);
            ctx.bezierCurveTo(23, 47, 23, 44, 23, 42);
            ctx.bezierCurveTo(23, 39, 23, 35, 22, 31);
            ctx.bezierCurveTo(22, 28, 20, 21, 19, 18);
            ctx.bezierCurveTo(18, 14, 16, 7, 15, 4);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 5, 10, 9, 10, 11);
            ctx.bezierCurveTo(10, 14, 9, 19, 10, 23);
            ctx.bezierCurveTo(9, 26, 8, 30, 8, 35);
            ctx.bezierCurveTo(7, 39, 8, 45, 8, 48);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 13:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 12:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 11:
          x = x + 4;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 21:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 22:
          x = x + 7;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 23:
          x = x + 7;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 24:
          x = x + 9;
          y = y + 4;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 25:
          x = x + 12;
          y = y + 14;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 41);
            ctx.bezierCurveTo(3, 41, 8, 40, 10, 40);
            ctx.bezierCurveTo(12, 40, 14, 41, 15, 42);
            ctx.bezierCurveTo(15, 40, 14, 37, 14, 36);
            ctx.bezierCurveTo(14, 34, 14, 31, 14, 28);
            ctx.bezierCurveTo(13, 25, 12, 20, 12, 18);
            ctx.bezierCurveTo(11, 15, 13, 11, 13, 7);
            ctx.bezierCurveTo(13, 7, 13, 5, 12, 5);
            ctx.bezierCurveTo(11, 4, 10, 5, 10, 6);
            ctx.bezierCurveTo(9, 7, 7, 8, 7, 10);
            ctx.bezierCurveTo(6, 13, 4, 18, 4, 23);
            ctx.bezierCurveTo(4, 26, 3, 27, 3, 31);
            ctx.bezierCurveTo(2, 33, 1, 37, 2, 41);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 26:
          x = x + 0;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 27:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 28:
          x = x + 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(10, 41);
            ctx.bezierCurveTo(12, 41, 17, 41, 20, 41);
            ctx.bezierCurveTo(21, 41, 26, 42, 27, 43);
            ctx.bezierCurveTo(27, 40, 27, 35, 27, 33);
            ctx.bezierCurveTo(27, 32, 28, 27, 28, 24);
            ctx.bezierCurveTo(29, 22, 30, 19, 31, 17);
            ctx.bezierCurveTo(31, 15, 32, 13, 32, 12);
            ctx.bezierCurveTo(32, 10, 31, 9, 29, 7);
            ctx.bezierCurveTo(27, 7, 27, 7, 26, 8);
            ctx.bezierCurveTo(25, 9, 23, 12, 22, 13);
            ctx.bezierCurveTo(21, 15, 17, 19, 16, 22);
            ctx.bezierCurveTo(15, 26, 12, 31, 11, 34);
            ctx.bezierCurveTo(10, 36, 10, 39, 10, 41);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 55:
          x = x + 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 44);
            ctx.bezierCurveTo(10, 44, 23, 44, 29, 44);
            ctx.bezierCurveTo(28, 42, 27, 38, 27, 35);
            ctx.bezierCurveTo(28, 33, 28, 27, 29, 24);
            ctx.bezierCurveTo(28, 21, 28, 15, 26, 12);
            ctx.bezierCurveTo(25, 10, 24, 6, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 13, 18, 21, 18, 25);
            ctx.bezierCurveTo(17, 26, 16, 29, 16, 30);
            ctx.bezierCurveTo(16, 28, 15, 24, 14, 20);
            ctx.bezierCurveTo(14, 16, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 6, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 10);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 18, 5, 23, 5, 25);
            ctx.bezierCurveTo(5, 28, 5, 32, 5, 34);
            ctx.bezierCurveTo(6, 36, 5, 39, 5, 41);
            ctx.bezierCurveTo(4, 42, 4, 43, 4, 44);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 54:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 46);
            ctx.bezierCurveTo(10, 46, 23, 46, 29, 46);
            ctx.bezierCurveTo(28, 44, 27, 40, 27, 37);
            ctx.bezierCurveTo(28, 34, 28, 28, 29, 25);
            ctx.bezierCurveTo(28, 21, 28, 16, 26, 13);
            ctx.bezierCurveTo(25, 10, 24, 7, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 14, 18, 22, 18, 26);
            ctx.bezierCurveTo(17, 27, 16, 30, 16, 32);
            ctx.bezierCurveTo(16, 29, 15, 25, 14, 21);
            ctx.bezierCurveTo(14, 17, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 7, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 11);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 19, 5, 24, 5, 26);
            ctx.bezierCurveTo(5, 29, 5, 33, 5, 36);
            ctx.bezierCurveTo(6, 38, 5, 41, 5, 43);
            ctx.bezierCurveTo(4, 44, 4, 45, 4, 46);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 53:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 52:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 51:
          x = x + 2;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 61:
          x = x + 6;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 62:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 63:
          x = x + 5;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 64:
          x = x + 1;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 65:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 48:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 12);
            ctx.bezierCurveTo(8, 13, 10, 12, 13, 13);
            ctx.bezierCurveTo(17, 12, 20, 12, 23, 12);
            ctx.bezierCurveTo(23, 16, 23, 22, 22, 26);
            ctx.bezierCurveTo(21, 30, 18, 35, 16, 38);
            ctx.bezierCurveTo(15, 40, 12, 43, 11, 43);
            ctx.bezierCurveTo(9, 42, 9, 42, 9, 41);
            ctx.bezierCurveTo(10, 39, 11, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 13, 30, 14, 28);
            ctx.bezierCurveTo(14, 27, 14, 23, 14, 21);
            ctx.bezierCurveTo(14, 23, 13, 28, 13, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 11, 35);
            ctx.bezierCurveTo(11, 36, 9, 39, 9, 40);
            ctx.bezierCurveTo(8, 40, 6, 41, 5, 42);
            ctx.bezierCurveTo(5, 42, 4, 43, 3, 42);
            ctx.bezierCurveTo(2, 42, 2, 42, 1, 40);
            ctx.bezierCurveTo(2, 38, 2, 37, 2, 37);
            ctx.bezierCurveTo(3, 36, 3, 36, 4, 35);
            ctx.bezierCurveTo(4, 34, 6, 31, 6, 30);
            ctx.bezierCurveTo(6, 29, 6, 28, 7, 25);
            ctx.bezierCurveTo(6, 23, 7, 20, 7, 16);
            ctx.bezierCurveTo(7, 14, 6, 14, 6, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 47:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 46:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 45:
          x = x + 11;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 0);
            ctx.bezierCurveTo(3, 0, 7, 1, 9, 1);
            ctx.bezierCurveTo(11, 1, 14, 0, 16, 0);
            ctx.bezierCurveTo(16, 7, 15, 20, 14, 27);
            ctx.bezierCurveTo(14, 31, 13, 37, 12, 41);
            ctx.bezierCurveTo(11, 43, 7, 46, 5, 47);
            ctx.bezierCurveTo(4, 47, 3, 47, 3, 45);
            ctx.bezierCurveTo(3, 43, 4, 40, 5, 37);
            ctx.bezierCurveTo(5, 34, 3, 23, 4, 19);
            ctx.bezierCurveTo(3, 18, 1, 5, 0, 0);
            ctx.bezierCurveTo(1, 0, 0, 0, 1, 0);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 44:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(3, 3);
            ctx.bezierCurveTo(5, 3, 7, 5, 9, 5);
            ctx.bezierCurveTo(11, 5, 14, 3, 16, 3);
            ctx.bezierCurveTo(17, 14, 13, 34, 11, 45);
            ctx.bezierCurveTo(10, 46, 9, 46, 8, 45);
            ctx.bezierCurveTo(5, 34, 3, 14, 3, 3);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 43:
          x = x + 3;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 42:
          x = x + 13;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 41:
          x = x + 11;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 38:
          x = x + 11;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(18, 12);
            ctx.bezierCurveTo(16, 13, 14, 12, 11, 13);
            ctx.bezierCurveTo(7, 12, 4, 12, 1, 12);
            ctx.bezierCurveTo(1, 16, 1, 22, 2, 26);
            ctx.bezierCurveTo(3, 30, 6, 35, 8, 38);
            ctx.bezierCurveTo(9, 40, 12, 43, 13, 43);
            ctx.bezierCurveTo(15, 42, 15, 42, 15, 41);
            ctx.bezierCurveTo(14, 39, 13, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 11, 30, 10, 28);
            ctx.bezierCurveTo(10, 27, 10, 23, 10, 21);
            ctx.bezierCurveTo(10, 23, 11, 28, 11, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 13, 35);
            ctx.bezierCurveTo(13, 36, 15, 39, 15, 40);
            ctx.bezierCurveTo(16, 40, 18, 41, 19, 42);
            ctx.bezierCurveTo(19, 42, 20, 43, 21, 42);
            ctx.bezierCurveTo(22, 42, 22, 42, 23, 40);
            ctx.bezierCurveTo(22, 38, 22, 37, 22, 37);
            ctx.bezierCurveTo(21, 36, 21, 36, 20, 35);
            ctx.bezierCurveTo(20, 34, 18, 31, 18, 30);
            ctx.bezierCurveTo(18, 29, 18, 28, 17, 25);
            ctx.bezierCurveTo(18, 23, 17, 20, 17, 16);
            ctx.bezierCurveTo(17, 14, 18, 14, 18, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 37:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 36:
          x = x + 6;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 35:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(15, 0);
            ctx.bezierCurveTo(13, 0, 9, 1, 7, 1);
            ctx.bezierCurveTo(5, 1, 2, 0, 0, 0);
            ctx.bezierCurveTo(0, 7, 1, 20, 2, 27);
            ctx.bezierCurveTo(2, 31, 3, 37, 4, 41);
            ctx.bezierCurveTo(5, 43, 9, 46, 11, 47);
            ctx.bezierCurveTo(12, 47, 13, 47, 13, 45);
            ctx.bezierCurveTo(13, 43, 12, 40, 11, 37);
            ctx.bezierCurveTo(11, 34, 13, 23, 12, 19);
            ctx.bezierCurveTo(13, 18, 15, 5, 16, 0);
            ctx.bezierCurveTo(15, 0, 16, 0, 15, 0);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 34:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(16, 3);
            ctx.bezierCurveTo(14, 3, 12, 5, 10, 5);
            ctx.bezierCurveTo(8, 5, 5, 3, 3, 3);
            ctx.bezierCurveTo(2, 14, 6, 34, 8, 45);
            ctx.bezierCurveTo(9, 46, 10, 46, 11, 45);
            ctx.bezierCurveTo(14, 34, 16, 14, 16, 3);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 33:
          x = x - 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 32:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 31:
          x = x + 14;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 85:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 84:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 83:
          x = x + 4;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 82:
          x = x + 12;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 81:
          x = x + 7;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 71:
          x = x + 15;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 72:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 73:
          x = x - 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 74:
          x = x + 7;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 75:
          x = x + 7;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#ff0000';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        default:
          break;
      }
      break;
    case all.ENDODONCIA:
      switch (point) {
        case 18:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 43);
            ctx.bezierCurveTo(NaN, NaN, 7, 41, 11, 40);
            ctx.bezierCurveTo(15, 41, 19, 42, 21, 43);
            ctx.bezierCurveTo(21, 40, 20, 35, 19, 32);
            ctx.bezierCurveTo(19, 29, 16, 23, 16, 20);
            ctx.bezierCurveTo(15, 18, 13, 14, 12, 11);
            ctx.bezierCurveTo(10, 9, 9, 8, 9, 7);
            ctx.bezierCurveTo(8, 6, 8, 4, 8, 2);
            ctx.bezierCurveTo(7, 1, 6, 0, 5, 0);
            ctx.bezierCurveTo(4, 0, 3, 1, 2, 2);
            ctx.bezierCurveTo(1, 4, 1, 5, 1, 7);
            ctx.bezierCurveTo(1, 11, 2, 14, 2, 17);
            ctx.bezierCurveTo(2, 20, 3, 25, 3, 28);
            ctx.bezierCurveTo(3, 30, 3, 35, 3, 37);
            ctx.bezierCurveTo(2, 39, NaN, NaN, 2, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 17:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.beginPath();
            ctx.moveTo(11, 39);
            ctx.bezierCurveTo(15, 39, 20, 39, 24, 39);
            ctx.bezierCurveTo(24, 37, 24, 35, 23, 33);
            ctx.bezierCurveTo(23, 31, 22, 30, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 22, 20, 19);
            ctx.bezierCurveTo(20, 16, 16, 11, 15, 8);
            ctx.bezierCurveTo(14, 7, 14, 7, 13, 7);
            ctx.bezierCurveTo(12, 7, 12, 8, 12, 9);
            ctx.bezierCurveTo(12, 9, 12, 11, 12, 12);
            ctx.bezierCurveTo(12, 13, 12, 15, 12, 16);
            ctx.bezierCurveTo(11, 18, 10, 21, 10, 22);
            ctx.bezierCurveTo(10, 21, 9, 17, 9, 15);
            ctx.bezierCurveTo(8, 14, 8, 11, 7, 10);
            ctx.bezierCurveTo(7, 9, 6, 8, 5, 7);
            ctx.bezierCurveTo(5, 7, 4, 6, 3, 6);
            ctx.bezierCurveTo(2, 7, 3, 7, 2, 8);
            ctx.bezierCurveTo(2, 10, 3, 11, 3, 13);
            ctx.bezierCurveTo(3, 14, 4, 15, 4, 17);
            ctx.bezierCurveTo(4, 20, 4, 29, 3, 33);
            ctx.bezierCurveTo(3, 35, 3, 35, 3, 40);
            ctx.lineTo(7, 39);
            ctx.lineTo(11, 39);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(11, 9);
            ctx.bezierCurveTo(11, 9, 10, 8, 10, 7);
            ctx.bezierCurveTo(9, 7, 8, 8, 7, 8);
            ctx.bezierCurveTo(7, 9, 8, 10, 8, 11);
            ctx.bezierCurveTo(8, 12, 9, 13, 9, 14);
            ctx.bezierCurveTo(9, 16, 10, 19, 10, 21);
            ctx.bezierCurveTo(11, 19, 12, 16, 12, 14);
            ctx.bezierCurveTo(12, 13, 11, 10, 11, 9);
            ctx.bezierCurveTo(11, 9, 11, 9, 11, 9);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 16:
          x = x + 8;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, 40);
            ctx.bezierCurveTo(6, 40, 16, 40, 23, 40);
            ctx.bezierCurveTo(23, 36, 22, 37, 22, 34);
            ctx.bezierCurveTo(23, 30, 24, 29, 24, 26);
            ctx.bezierCurveTo(24, 22, 23, 19, 23, 17);
            ctx.bezierCurveTo(23, 14, 21, 8, 20, 4);
            ctx.bezierCurveTo(20, 2, 19, 1, 18, 1);
            ctx.bezierCurveTo(18, 0, 18, 0, 16, 0);
            ctx.bezierCurveTo(16, 2, 16, 3, 16, 3);
            ctx.bezierCurveTo(16, 5, 16, 8, 16, 10);
            ctx.bezierCurveTo(16, 12, 16, 17, 16, 19);
            ctx.bezierCurveTo(15, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(12, 21, 11, 16, 11, 14);
            ctx.bezierCurveTo(10, 11, 9, 5, 8, 3);
            ctx.bezierCurveTo(7, 1, 7, 2, 7, 2);
            ctx.bezierCurveTo(5, 2, 4, 2, 4, 3);
            ctx.bezierCurveTo(4, 5, 4, 10, 4, 13);
            ctx.bezierCurveTo(5, 14, 5, 17, 5, 19);
            ctx.bezierCurveTo(5, 20, 5, 20, 5, 23);
            ctx.bezierCurveTo(4, 26, 4, 30, 3, 32);
            ctx.bezierCurveTo(2, 34, 1, 38, 0, 40);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(16, 5);
            ctx.bezierCurveTo(15, 4, 14, 3, 13, 2);
            ctx.bezierCurveTo(12, 2, 11, 3, 10, 3);
            ctx.bezierCurveTo(10, 4, 9, 5, 9, 6);
            ctx.bezierCurveTo(10, 8, 11, 11, 11, 13);
            ctx.bezierCurveTo(11, 15, 12, 18, 12, 19);
            ctx.bezierCurveTo(12, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(14, 22, 15, 21, 16, 20);
            ctx.bezierCurveTo(16, 18, 16, 13, 16, 10);
            ctx.bezierCurveTo(16, 9, 16, 6, 16, 5);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 15:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 43);
            ctx.bezierCurveTo(10, 43, 13, 42, 16, 42);
            ctx.bezierCurveTo(20, 42, 22, 43, 23, 44);
            ctx.bezierCurveTo(23, 42, 23, 39, 23, 38);
            ctx.bezierCurveTo(23, 35, 23, 31, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 19, 19, 16);
            ctx.bezierCurveTo(18, 13, 16, 7, 15, 3);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 4, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 10, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 8, 31);
            ctx.bezierCurveTo(7, 35, 8, 40, 8, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 14:
          x = x + 2;
          y = y + 7;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 48);
            ctx.bezierCurveTo(10, 48, 13, 46, 16, 46);
            ctx.bezierCurveTo(20, 47, 22, 48, 23, 49);
            ctx.bezierCurveTo(23, 47, 23, 44, 23, 42);
            ctx.bezierCurveTo(23, 39, 23, 35, 22, 31);
            ctx.bezierCurveTo(22, 28, 20, 21, 19, 18);
            ctx.bezierCurveTo(18, 14, 16, 7, 15, 4);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 5, 10, 9, 10, 11);
            ctx.bezierCurveTo(10, 14, 9, 19, 10, 23);
            ctx.bezierCurveTo(9, 26, 8, 30, 8, 35);
            ctx.bezierCurveTo(7, 39, 8, 45, 8, 48);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 13:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 12:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 11:
          x = x + 4;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 21:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 22:
          x = x + 7;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 23:
          x = x + 7;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 24:
          x = x + 9;
          y = y + 4;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 25:
          x = x + 12;
          y = y + 14;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 41);
            ctx.bezierCurveTo(3, 41, 8, 40, 10, 40);
            ctx.bezierCurveTo(12, 40, 14, 41, 15, 42);
            ctx.bezierCurveTo(15, 40, 14, 37, 14, 36);
            ctx.bezierCurveTo(14, 34, 14, 31, 14, 28);
            ctx.bezierCurveTo(13, 25, 12, 20, 12, 18);
            ctx.bezierCurveTo(11, 15, 13, 11, 13, 7);
            ctx.bezierCurveTo(13, 7, 13, 5, 12, 5);
            ctx.bezierCurveTo(11, 4, 10, 5, 10, 6);
            ctx.bezierCurveTo(9, 7, 7, 8, 7, 10);
            ctx.bezierCurveTo(6, 13, 4, 18, 4, 23);
            ctx.bezierCurveTo(4, 26, 3, 27, 3, 31);
            ctx.bezierCurveTo(2, 33, 1, 37, 2, 41);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 26:
          x = x + 0;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 27:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 28:
          x = x + 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(10, 41);
            ctx.bezierCurveTo(12, 41, 17, 41, 20, 41);
            ctx.bezierCurveTo(21, 41, 26, 42, 27, 43);
            ctx.bezierCurveTo(27, 40, 27, 35, 27, 33);
            ctx.bezierCurveTo(27, 32, 28, 27, 28, 24);
            ctx.bezierCurveTo(29, 22, 30, 19, 31, 17);
            ctx.bezierCurveTo(31, 15, 32, 13, 32, 12);
            ctx.bezierCurveTo(32, 10, 31, 9, 29, 7);
            ctx.bezierCurveTo(27, 7, 27, 7, 26, 8);
            ctx.bezierCurveTo(25, 9, 23, 12, 22, 13);
            ctx.bezierCurveTo(21, 15, 17, 19, 16, 22);
            ctx.bezierCurveTo(15, 26, 12, 31, 11, 34);
            ctx.bezierCurveTo(10, 36, 10, 39, 10, 41);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 55:
          x = x + 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 44);
            ctx.bezierCurveTo(10, 44, 23, 44, 29, 44);
            ctx.bezierCurveTo(28, 42, 27, 38, 27, 35);
            ctx.bezierCurveTo(28, 33, 28, 27, 29, 24);
            ctx.bezierCurveTo(28, 21, 28, 15, 26, 12);
            ctx.bezierCurveTo(25, 10, 24, 6, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 13, 18, 21, 18, 25);
            ctx.bezierCurveTo(17, 26, 16, 29, 16, 30);
            ctx.bezierCurveTo(16, 28, 15, 24, 14, 20);
            ctx.bezierCurveTo(14, 16, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 6, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 10);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 18, 5, 23, 5, 25);
            ctx.bezierCurveTo(5, 28, 5, 32, 5, 34);
            ctx.bezierCurveTo(6, 36, 5, 39, 5, 41);
            ctx.bezierCurveTo(4, 42, 4, 43, 4, 44);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 54:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 46);
            ctx.bezierCurveTo(10, 46, 23, 46, 29, 46);
            ctx.bezierCurveTo(28, 44, 27, 40, 27, 37);
            ctx.bezierCurveTo(28, 34, 28, 28, 29, 25);
            ctx.bezierCurveTo(28, 21, 28, 16, 26, 13);
            ctx.bezierCurveTo(25, 10, 24, 7, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 14, 18, 22, 18, 26);
            ctx.bezierCurveTo(17, 27, 16, 30, 16, 32);
            ctx.bezierCurveTo(16, 29, 15, 25, 14, 21);
            ctx.bezierCurveTo(14, 17, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 7, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 11);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 19, 5, 24, 5, 26);
            ctx.bezierCurveTo(5, 29, 5, 33, 5, 36);
            ctx.bezierCurveTo(6, 38, 5, 41, 5, 43);
            ctx.bezierCurveTo(4, 44, 4, 45, 4, 46);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 53:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 52:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 51:
          x = x + 2;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 61:
          x = x + 6;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 62:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 63:
          x = x + 5;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 64:
          x = x + 1;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 65:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 48:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 12);
            ctx.bezierCurveTo(8, 13, 10, 12, 13, 13);
            ctx.bezierCurveTo(17, 12, 20, 12, 23, 12);
            ctx.bezierCurveTo(23, 16, 23, 22, 22, 26);
            ctx.bezierCurveTo(21, 30, 18, 35, 16, 38);
            ctx.bezierCurveTo(15, 40, 12, 43, 11, 43);
            ctx.bezierCurveTo(9, 42, 9, 42, 9, 41);
            ctx.bezierCurveTo(10, 39, 11, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 13, 30, 14, 28);
            ctx.bezierCurveTo(14, 27, 14, 23, 14, 21);
            ctx.bezierCurveTo(14, 23, 13, 28, 13, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 11, 35);
            ctx.bezierCurveTo(11, 36, 9, 39, 9, 40);
            ctx.bezierCurveTo(8, 40, 6, 41, 5, 42);
            ctx.bezierCurveTo(5, 42, 4, 43, 3, 42);
            ctx.bezierCurveTo(2, 42, 2, 42, 1, 40);
            ctx.bezierCurveTo(2, 38, 2, 37, 2, 37);
            ctx.bezierCurveTo(3, 36, 3, 36, 4, 35);
            ctx.bezierCurveTo(4, 34, 6, 31, 6, 30);
            ctx.bezierCurveTo(6, 29, 6, 28, 7, 25);
            ctx.bezierCurveTo(6, 23, 7, 20, 7, 16);
            ctx.bezierCurveTo(7, 14, 6, 14, 6, 12);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 47:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 46:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 45:
          x = x + 11;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 0);
            ctx.bezierCurveTo(3, 0, 7, 1, 9, 1);
            ctx.bezierCurveTo(11, 1, 14, 0, 16, 0);
            ctx.bezierCurveTo(16, 7, 15, 20, 14, 27);
            ctx.bezierCurveTo(14, 31, 13, 37, 12, 41);
            ctx.bezierCurveTo(11, 43, 7, 46, 5, 47);
            ctx.bezierCurveTo(4, 47, 3, 47, 3, 45);
            ctx.bezierCurveTo(3, 43, 4, 40, 5, 37);
            ctx.bezierCurveTo(5, 34, 3, 23, 4, 19);
            ctx.bezierCurveTo(3, 18, 1, 5, 0, 0);
            ctx.bezierCurveTo(1, 0, 0, 0, 1, 0);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 44:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(3, 3);
            ctx.bezierCurveTo(5, 3, 7, 5, 9, 5);
            ctx.bezierCurveTo(11, 5, 14, 3, 16, 3);
            ctx.bezierCurveTo(17, 14, 13, 34, 11, 45);
            ctx.bezierCurveTo(10, 46, 9, 46, 8, 45);
            ctx.bezierCurveTo(5, 34, 3, 14, 3, 3);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 43:
          x = x + 3;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 42:
          x = x + 13;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 41:
          x = x + 11;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 38:
          x = x + 11;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(18, 12);
            ctx.bezierCurveTo(16, 13, 14, 12, 11, 13);
            ctx.bezierCurveTo(7, 12, 4, 12, 1, 12);
            ctx.bezierCurveTo(1, 16, 1, 22, 2, 26);
            ctx.bezierCurveTo(3, 30, 6, 35, 8, 38);
            ctx.bezierCurveTo(9, 40, 12, 43, 13, 43);
            ctx.bezierCurveTo(15, 42, 15, 42, 15, 41);
            ctx.bezierCurveTo(14, 39, 13, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 11, 30, 10, 28);
            ctx.bezierCurveTo(10, 27, 10, 23, 10, 21);
            ctx.bezierCurveTo(10, 23, 11, 28, 11, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 13, 35);
            ctx.bezierCurveTo(13, 36, 15, 39, 15, 40);
            ctx.bezierCurveTo(16, 40, 18, 41, 19, 42);
            ctx.bezierCurveTo(19, 42, 20, 43, 21, 42);
            ctx.bezierCurveTo(22, 42, 22, 42, 23, 40);
            ctx.bezierCurveTo(22, 38, 22, 37, 22, 37);
            ctx.bezierCurveTo(21, 36, 21, 36, 20, 35);
            ctx.bezierCurveTo(20, 34, 18, 31, 18, 30);
            ctx.bezierCurveTo(18, 29, 18, 28, 17, 25);
            ctx.bezierCurveTo(18, 23, 17, 20, 17, 16);
            ctx.bezierCurveTo(17, 14, 18, 14, 18, 12);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 37:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 36:
          x = x + 6;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 35:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(15, 0);
            ctx.bezierCurveTo(13, 0, 9, 1, 7, 1);
            ctx.bezierCurveTo(5, 1, 2, 0, 0, 0);
            ctx.bezierCurveTo(0, 7, 1, 20, 2, 27);
            ctx.bezierCurveTo(2, 31, 3, 37, 4, 41);
            ctx.bezierCurveTo(5, 43, 9, 46, 11, 47);
            ctx.bezierCurveTo(12, 47, 13, 47, 13, 45);
            ctx.bezierCurveTo(13, 43, 12, 40, 11, 37);
            ctx.bezierCurveTo(11, 34, 13, 23, 12, 19);
            ctx.bezierCurveTo(13, 18, 15, 5, 16, 0);
            ctx.bezierCurveTo(15, 0, 16, 0, 15, 0);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 34:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(16, 3);
            ctx.bezierCurveTo(14, 3, 12, 5, 10, 5);
            ctx.bezierCurveTo(8, 5, 5, 3, 3, 3);
            ctx.bezierCurveTo(2, 14, 6, 34, 8, 45);
            ctx.bezierCurveTo(9, 46, 10, 46, 11, 45);
            ctx.bezierCurveTo(14, 34, 16, 14, 16, 3);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 33:
          x = x - 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 32:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 31:
          x = x + 14;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 85:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 84:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 83:
          x = x + 4;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 82:
          x = x + 12;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 81:
          x = x + 7;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 71:
          x = x + 15;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 72:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 73:
          x = x - 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 74:
          x = x + 7;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 75:
          x = x + 7;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#0000ff';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        default:
          break;
      }
      break;
    case all.ENDODONCIA_INSTRUMENTACION:
      switch (point) {
        case 18:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 43);
            ctx.bezierCurveTo(NaN, NaN, 7, 41, 11, 40);
            ctx.bezierCurveTo(15, 41, 19, 42, 21, 43);
            ctx.bezierCurveTo(21, 40, 20, 35, 19, 32);
            ctx.bezierCurveTo(19, 29, 16, 23, 16, 20);
            ctx.bezierCurveTo(15, 18, 13, 14, 12, 11);
            ctx.bezierCurveTo(10, 9, 9, 8, 9, 7);
            ctx.bezierCurveTo(8, 6, 8, 4, 8, 2);
            ctx.bezierCurveTo(7, 1, 6, 0, 5, 0);
            ctx.bezierCurveTo(4, 0, 3, 1, 2, 2);
            ctx.bezierCurveTo(1, 4, 1, 5, 1, 7);
            ctx.bezierCurveTo(1, 11, 2, 14, 2, 17);
            ctx.bezierCurveTo(2, 20, 3, 25, 3, 28);
            ctx.bezierCurveTo(3, 30, 3, 35, 3, 37);
            ctx.bezierCurveTo(2, 39, NaN, NaN, 2, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 17:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.beginPath();
            ctx.moveTo(11, 39);
            ctx.bezierCurveTo(15, 39, 20, 39, 24, 39);
            ctx.bezierCurveTo(24, 37, 24, 35, 23, 33);
            ctx.bezierCurveTo(23, 31, 22, 30, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 22, 20, 19);
            ctx.bezierCurveTo(20, 16, 16, 11, 15, 8);
            ctx.bezierCurveTo(14, 7, 14, 7, 13, 7);
            ctx.bezierCurveTo(12, 7, 12, 8, 12, 9);
            ctx.bezierCurveTo(12, 9, 12, 11, 12, 12);
            ctx.bezierCurveTo(12, 13, 12, 15, 12, 16);
            ctx.bezierCurveTo(11, 18, 10, 21, 10, 22);
            ctx.bezierCurveTo(10, 21, 9, 17, 9, 15);
            ctx.bezierCurveTo(8, 14, 8, 11, 7, 10);
            ctx.bezierCurveTo(7, 9, 6, 8, 5, 7);
            ctx.bezierCurveTo(5, 7, 4, 6, 3, 6);
            ctx.bezierCurveTo(2, 7, 3, 7, 2, 8);
            ctx.bezierCurveTo(2, 10, 3, 11, 3, 13);
            ctx.bezierCurveTo(3, 14, 4, 15, 4, 17);
            ctx.bezierCurveTo(4, 20, 4, 29, 3, 33);
            ctx.bezierCurveTo(3, 35, 3, 35, 3, 40);
            ctx.lineTo(7, 39);
            ctx.lineTo(11, 39);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(11, 9);
            ctx.bezierCurveTo(11, 9, 10, 8, 10, 7);
            ctx.bezierCurveTo(9, 7, 8, 8, 7, 8);
            ctx.bezierCurveTo(7, 9, 8, 10, 8, 11);
            ctx.bezierCurveTo(8, 12, 9, 13, 9, 14);
            ctx.bezierCurveTo(9, 16, 10, 19, 10, 21);
            ctx.bezierCurveTo(11, 19, 12, 16, 12, 14);
            ctx.bezierCurveTo(12, 13, 11, 10, 11, 9);
            ctx.bezierCurveTo(11, 9, 11, 9, 11, 9);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 16:
          x = x + 8;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, 40);
            ctx.bezierCurveTo(6, 40, 16, 40, 23, 40);
            ctx.bezierCurveTo(23, 36, 22, 37, 22, 34);
            ctx.bezierCurveTo(23, 30, 24, 29, 24, 26);
            ctx.bezierCurveTo(24, 22, 23, 19, 23, 17);
            ctx.bezierCurveTo(23, 14, 21, 8, 20, 4);
            ctx.bezierCurveTo(20, 2, 19, 1, 18, 1);
            ctx.bezierCurveTo(18, 0, 18, 0, 16, 0);
            ctx.bezierCurveTo(16, 2, 16, 3, 16, 3);
            ctx.bezierCurveTo(16, 5, 16, 8, 16, 10);
            ctx.bezierCurveTo(16, 12, 16, 17, 16, 19);
            ctx.bezierCurveTo(15, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(12, 21, 11, 16, 11, 14);
            ctx.bezierCurveTo(10, 11, 9, 5, 8, 3);
            ctx.bezierCurveTo(7, 1, 7, 2, 7, 2);
            ctx.bezierCurveTo(5, 2, 4, 2, 4, 3);
            ctx.bezierCurveTo(4, 5, 4, 10, 4, 13);
            ctx.bezierCurveTo(5, 14, 5, 17, 5, 19);
            ctx.bezierCurveTo(5, 20, 5, 20, 5, 23);
            ctx.bezierCurveTo(4, 26, 4, 30, 3, 32);
            ctx.bezierCurveTo(2, 34, 1, 38, 0, 40);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(16, 5);
            ctx.bezierCurveTo(15, 4, 14, 3, 13, 2);
            ctx.bezierCurveTo(12, 2, 11, 3, 10, 3);
            ctx.bezierCurveTo(10, 4, 9, 5, 9, 6);
            ctx.bezierCurveTo(10, 8, 11, 11, 11, 13);
            ctx.bezierCurveTo(11, 15, 12, 18, 12, 19);
            ctx.bezierCurveTo(12, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(14, 22, 15, 21, 16, 20);
            ctx.bezierCurveTo(16, 18, 16, 13, 16, 10);
            ctx.bezierCurveTo(16, 9, 16, 6, 16, 5);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 15:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 43);
            ctx.bezierCurveTo(10, 43, 13, 42, 16, 42);
            ctx.bezierCurveTo(20, 42, 22, 43, 23, 44);
            ctx.bezierCurveTo(23, 42, 23, 39, 23, 38);
            ctx.bezierCurveTo(23, 35, 23, 31, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 19, 19, 16);
            ctx.bezierCurveTo(18, 13, 16, 7, 15, 3);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 4, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 10, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 8, 31);
            ctx.bezierCurveTo(7, 35, 8, 40, 8, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 14:
          x = x + 2;
          y = y + 7;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 48);
            ctx.bezierCurveTo(10, 48, 13, 46, 16, 46);
            ctx.bezierCurveTo(20, 47, 22, 48, 23, 49);
            ctx.bezierCurveTo(23, 47, 23, 44, 23, 42);
            ctx.bezierCurveTo(23, 39, 23, 35, 22, 31);
            ctx.bezierCurveTo(22, 28, 20, 21, 19, 18);
            ctx.bezierCurveTo(18, 14, 16, 7, 15, 4);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 5, 10, 9, 10, 11);
            ctx.bezierCurveTo(10, 14, 9, 19, 10, 23);
            ctx.bezierCurveTo(9, 26, 8, 30, 8, 35);
            ctx.bezierCurveTo(7, 39, 8, 45, 8, 48);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 13:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 12:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 11:
          x = x + 4;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 21:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 22:
          x = x + 7;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 23:
          x = x + 7;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 24:
          x = x + 9;
          y = y + 4;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 25:
          x = x + 12;
          y = y + 14;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 41);
            ctx.bezierCurveTo(3, 41, 8, 40, 10, 40);
            ctx.bezierCurveTo(12, 40, 14, 41, 15, 42);
            ctx.bezierCurveTo(15, 40, 14, 37, 14, 36);
            ctx.bezierCurveTo(14, 34, 14, 31, 14, 28);
            ctx.bezierCurveTo(13, 25, 12, 20, 12, 18);
            ctx.bezierCurveTo(11, 15, 13, 11, 13, 7);
            ctx.bezierCurveTo(13, 7, 13, 5, 12, 5);
            ctx.bezierCurveTo(11, 4, 10, 5, 10, 6);
            ctx.bezierCurveTo(9, 7, 7, 8, 7, 10);
            ctx.bezierCurveTo(6, 13, 4, 18, 4, 23);
            ctx.bezierCurveTo(4, 26, 3, 27, 3, 31);
            ctx.bezierCurveTo(2, 33, 1, 37, 2, 41);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 26:
          x = x + 0;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 27:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 28:
          x = x + 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(10, 41);
            ctx.bezierCurveTo(12, 41, 17, 41, 20, 41);
            ctx.bezierCurveTo(21, 41, 26, 42, 27, 43);
            ctx.bezierCurveTo(27, 40, 27, 35, 27, 33);
            ctx.bezierCurveTo(27, 32, 28, 27, 28, 24);
            ctx.bezierCurveTo(29, 22, 30, 19, 31, 17);
            ctx.bezierCurveTo(31, 15, 32, 13, 32, 12);
            ctx.bezierCurveTo(32, 10, 31, 9, 29, 7);
            ctx.bezierCurveTo(27, 7, 27, 7, 26, 8);
            ctx.bezierCurveTo(25, 9, 23, 12, 22, 13);
            ctx.bezierCurveTo(21, 15, 17, 19, 16, 22);
            ctx.bezierCurveTo(15, 26, 12, 31, 11, 34);
            ctx.bezierCurveTo(10, 36, 10, 39, 10, 41);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 55:
          x = x + 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 44);
            ctx.bezierCurveTo(10, 44, 23, 44, 29, 44);
            ctx.bezierCurveTo(28, 42, 27, 38, 27, 35);
            ctx.bezierCurveTo(28, 33, 28, 27, 29, 24);
            ctx.bezierCurveTo(28, 21, 28, 15, 26, 12);
            ctx.bezierCurveTo(25, 10, 24, 6, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 13, 18, 21, 18, 25);
            ctx.bezierCurveTo(17, 26, 16, 29, 16, 30);
            ctx.bezierCurveTo(16, 28, 15, 24, 14, 20);
            ctx.bezierCurveTo(14, 16, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 6, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 10);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 18, 5, 23, 5, 25);
            ctx.bezierCurveTo(5, 28, 5, 32, 5, 34);
            ctx.bezierCurveTo(6, 36, 5, 39, 5, 41);
            ctx.bezierCurveTo(4, 42, 4, 43, 4, 44);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 54:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 46);
            ctx.bezierCurveTo(10, 46, 23, 46, 29, 46);
            ctx.bezierCurveTo(28, 44, 27, 40, 27, 37);
            ctx.bezierCurveTo(28, 34, 28, 28, 29, 25);
            ctx.bezierCurveTo(28, 21, 28, 16, 26, 13);
            ctx.bezierCurveTo(25, 10, 24, 7, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 14, 18, 22, 18, 26);
            ctx.bezierCurveTo(17, 27, 16, 30, 16, 32);
            ctx.bezierCurveTo(16, 29, 15, 25, 14, 21);
            ctx.bezierCurveTo(14, 17, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 7, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 11);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 19, 5, 24, 5, 26);
            ctx.bezierCurveTo(5, 29, 5, 33, 5, 36);
            ctx.bezierCurveTo(6, 38, 5, 41, 5, 43);
            ctx.bezierCurveTo(4, 44, 4, 45, 4, 46);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 53:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 52:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 51:
          x = x + 2;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 61:
          x = x + 6;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 62:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 63:
          x = x + 5;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 64:
          x = x + 1;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 65:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 48:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 12);
            ctx.bezierCurveTo(8, 13, 10, 12, 13, 13);
            ctx.bezierCurveTo(17, 12, 20, 12, 23, 12);
            ctx.bezierCurveTo(23, 16, 23, 22, 22, 26);
            ctx.bezierCurveTo(21, 30, 18, 35, 16, 38);
            ctx.bezierCurveTo(15, 40, 12, 43, 11, 43);
            ctx.bezierCurveTo(9, 42, 9, 42, 9, 41);
            ctx.bezierCurveTo(10, 39, 11, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 13, 30, 14, 28);
            ctx.bezierCurveTo(14, 27, 14, 23, 14, 21);
            ctx.bezierCurveTo(14, 23, 13, 28, 13, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 11, 35);
            ctx.bezierCurveTo(11, 36, 9, 39, 9, 40);
            ctx.bezierCurveTo(8, 40, 6, 41, 5, 42);
            ctx.bezierCurveTo(5, 42, 4, 43, 3, 42);
            ctx.bezierCurveTo(2, 42, 2, 42, 1, 40);
            ctx.bezierCurveTo(2, 38, 2, 37, 2, 37);
            ctx.bezierCurveTo(3, 36, 3, 36, 4, 35);
            ctx.bezierCurveTo(4, 34, 6, 31, 6, 30);
            ctx.bezierCurveTo(6, 29, 6, 28, 7, 25);
            ctx.bezierCurveTo(6, 23, 7, 20, 7, 16);
            ctx.bezierCurveTo(7, 14, 6, 14, 6, 12);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 47:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 46:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 45:
          x = x + 11;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 0);
            ctx.bezierCurveTo(3, 0, 7, 1, 9, 1);
            ctx.bezierCurveTo(11, 1, 14, 0, 16, 0);
            ctx.bezierCurveTo(16, 7, 15, 20, 14, 27);
            ctx.bezierCurveTo(14, 31, 13, 37, 12, 41);
            ctx.bezierCurveTo(11, 43, 7, 46, 5, 47);
            ctx.bezierCurveTo(4, 47, 3, 47, 3, 45);
            ctx.bezierCurveTo(3, 43, 4, 40, 5, 37);
            ctx.bezierCurveTo(5, 34, 3, 23, 4, 19);
            ctx.bezierCurveTo(3, 18, 1, 5, 0, 0);
            ctx.bezierCurveTo(1, 0, 0, 0, 1, 0);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 44:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(3, 3);
            ctx.bezierCurveTo(5, 3, 7, 5, 9, 5);
            ctx.bezierCurveTo(11, 5, 14, 3, 16, 3);
            ctx.bezierCurveTo(17, 14, 13, 34, 11, 45);
            ctx.bezierCurveTo(10, 46, 9, 46, 8, 45);
            ctx.bezierCurveTo(5, 34, 3, 14, 3, 3);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 43:
          x = x + 3;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 42:
          x = x + 13;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 41:
          x = x + 11;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 38:
          x = x + 11;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(18, 12);
            ctx.bezierCurveTo(16, 13, 14, 12, 11, 13);
            ctx.bezierCurveTo(7, 12, 4, 12, 1, 12);
            ctx.bezierCurveTo(1, 16, 1, 22, 2, 26);
            ctx.bezierCurveTo(3, 30, 6, 35, 8, 38);
            ctx.bezierCurveTo(9, 40, 12, 43, 13, 43);
            ctx.bezierCurveTo(15, 42, 15, 42, 15, 41);
            ctx.bezierCurveTo(14, 39, 13, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 11, 30, 10, 28);
            ctx.bezierCurveTo(10, 27, 10, 23, 10, 21);
            ctx.bezierCurveTo(10, 23, 11, 28, 11, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 13, 35);
            ctx.bezierCurveTo(13, 36, 15, 39, 15, 40);
            ctx.bezierCurveTo(16, 40, 18, 41, 19, 42);
            ctx.bezierCurveTo(19, 42, 20, 43, 21, 42);
            ctx.bezierCurveTo(22, 42, 22, 42, 23, 40);
            ctx.bezierCurveTo(22, 38, 22, 37, 22, 37);
            ctx.bezierCurveTo(21, 36, 21, 36, 20, 35);
            ctx.bezierCurveTo(20, 34, 18, 31, 18, 30);
            ctx.bezierCurveTo(18, 29, 18, 28, 17, 25);
            ctx.bezierCurveTo(18, 23, 17, 20, 17, 16);
            ctx.bezierCurveTo(17, 14, 18, 14, 18, 12);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 37:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 36:
          x = x + 6;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 35:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(15, 0);
            ctx.bezierCurveTo(13, 0, 9, 1, 7, 1);
            ctx.bezierCurveTo(5, 1, 2, 0, 0, 0);
            ctx.bezierCurveTo(0, 7, 1, 20, 2, 27);
            ctx.bezierCurveTo(2, 31, 3, 37, 4, 41);
            ctx.bezierCurveTo(5, 43, 9, 46, 11, 47);
            ctx.bezierCurveTo(12, 47, 13, 47, 13, 45);
            ctx.bezierCurveTo(13, 43, 12, 40, 11, 37);
            ctx.bezierCurveTo(11, 34, 13, 23, 12, 19);
            ctx.bezierCurveTo(13, 18, 15, 5, 16, 0);
            ctx.bezierCurveTo(15, 0, 16, 0, 15, 0);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 34:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(16, 3);
            ctx.bezierCurveTo(14, 3, 12, 5, 10, 5);
            ctx.bezierCurveTo(8, 5, 5, 3, 3, 3);
            ctx.bezierCurveTo(2, 14, 6, 34, 8, 45);
            ctx.bezierCurveTo(9, 46, 10, 46, 11, 45);
            ctx.bezierCurveTo(14, 34, 16, 14, 16, 3);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 33:
          x = x - 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 32:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 31:
          x = x + 14;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 85:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 84:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 83:
          x = x + 4;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 82:
          x = x + 12;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 81:
          x = x + 7;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 71:
          x = x + 15;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 72:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 73:
          x = x - 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 74:
          x = x + 7;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 75:
          x = x + 7;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#e7ed2d';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        default:
          break;
      }
      break;
    case all.ENDODONCIA_CONDUCTOMETRIA:
      switch (point) {
        case 18:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 43);
            ctx.bezierCurveTo(NaN, NaN, 7, 41, 11, 40);
            ctx.bezierCurveTo(15, 41, 19, 42, 21, 43);
            ctx.bezierCurveTo(21, 40, 20, 35, 19, 32);
            ctx.bezierCurveTo(19, 29, 16, 23, 16, 20);
            ctx.bezierCurveTo(15, 18, 13, 14, 12, 11);
            ctx.bezierCurveTo(10, 9, 9, 8, 9, 7);
            ctx.bezierCurveTo(8, 6, 8, 4, 8, 2);
            ctx.bezierCurveTo(7, 1, 6, 0, 5, 0);
            ctx.bezierCurveTo(4, 0, 3, 1, 2, 2);
            ctx.bezierCurveTo(1, 4, 1, 5, 1, 7);
            ctx.bezierCurveTo(1, 11, 2, 14, 2, 17);
            ctx.bezierCurveTo(2, 20, 3, 25, 3, 28);
            ctx.bezierCurveTo(3, 30, 3, 35, 3, 37);
            ctx.bezierCurveTo(2, 39, NaN, NaN, 2, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 17:
          x = x + 5;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.beginPath();
            ctx.moveTo(11, 39);
            ctx.bezierCurveTo(15, 39, 20, 39, 24, 39);
            ctx.bezierCurveTo(24, 37, 24, 35, 23, 33);
            ctx.bezierCurveTo(23, 31, 22, 30, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 22, 20, 19);
            ctx.bezierCurveTo(20, 16, 16, 11, 15, 8);
            ctx.bezierCurveTo(14, 7, 14, 7, 13, 7);
            ctx.bezierCurveTo(12, 7, 12, 8, 12, 9);
            ctx.bezierCurveTo(12, 9, 12, 11, 12, 12);
            ctx.bezierCurveTo(12, 13, 12, 15, 12, 16);
            ctx.bezierCurveTo(11, 18, 10, 21, 10, 22);
            ctx.bezierCurveTo(10, 21, 9, 17, 9, 15);
            ctx.bezierCurveTo(8, 14, 8, 11, 7, 10);
            ctx.bezierCurveTo(7, 9, 6, 8, 5, 7);
            ctx.bezierCurveTo(5, 7, 4, 6, 3, 6);
            ctx.bezierCurveTo(2, 7, 3, 7, 2, 8);
            ctx.bezierCurveTo(2, 10, 3, 11, 3, 13);
            ctx.bezierCurveTo(3, 14, 4, 15, 4, 17);
            ctx.bezierCurveTo(4, 20, 4, 29, 3, 33);
            ctx.bezierCurveTo(3, 35, 3, 35, 3, 40);
            ctx.lineTo(7, 39);
            ctx.lineTo(11, 39);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(11, 9);
            ctx.bezierCurveTo(11, 9, 10, 8, 10, 7);
            ctx.bezierCurveTo(9, 7, 8, 8, 7, 8);
            ctx.bezierCurveTo(7, 9, 8, 10, 8, 11);
            ctx.bezierCurveTo(8, 12, 9, 13, 9, 14);
            ctx.bezierCurveTo(9, 16, 10, 19, 10, 21);
            ctx.bezierCurveTo(11, 19, 12, 16, 12, 14);
            ctx.bezierCurveTo(12, 13, 11, 10, 11, 9);
            ctx.bezierCurveTo(11, 9, 11, 9, 11, 9);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 16:
          x = x + 8;
          y = y + 20;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, 40);
            ctx.bezierCurveTo(6, 40, 16, 40, 23, 40);
            ctx.bezierCurveTo(23, 36, 22, 37, 22, 34);
            ctx.bezierCurveTo(23, 30, 24, 29, 24, 26);
            ctx.bezierCurveTo(24, 22, 23, 19, 23, 17);
            ctx.bezierCurveTo(23, 14, 21, 8, 20, 4);
            ctx.bezierCurveTo(20, 2, 19, 1, 18, 1);
            ctx.bezierCurveTo(18, 0, 18, 0, 16, 0);
            ctx.bezierCurveTo(16, 2, 16, 3, 16, 3);
            ctx.bezierCurveTo(16, 5, 16, 8, 16, 10);
            ctx.bezierCurveTo(16, 12, 16, 17, 16, 19);
            ctx.bezierCurveTo(15, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(12, 21, 11, 16, 11, 14);
            ctx.bezierCurveTo(10, 11, 9, 5, 8, 3);
            ctx.bezierCurveTo(7, 1, 7, 2, 7, 2);
            ctx.bezierCurveTo(5, 2, 4, 2, 4, 3);
            ctx.bezierCurveTo(4, 5, 4, 10, 4, 13);
            ctx.bezierCurveTo(5, 14, 5, 17, 5, 19);
            ctx.bezierCurveTo(5, 20, 5, 20, 5, 23);
            ctx.bezierCurveTo(4, 26, 4, 30, 3, 32);
            ctx.bezierCurveTo(2, 34, 1, 38, 0, 40);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(16, 5);
            ctx.bezierCurveTo(15, 4, 14, 3, 13, 2);
            ctx.bezierCurveTo(12, 2, 11, 3, 10, 3);
            ctx.bezierCurveTo(10, 4, 9, 5, 9, 6);
            ctx.bezierCurveTo(10, 8, 11, 11, 11, 13);
            ctx.bezierCurveTo(11, 15, 12, 18, 12, 19);
            ctx.bezierCurveTo(12, 20, 13, 22, 13, 23);
            ctx.bezierCurveTo(14, 22, 15, 21, 16, 20);
            ctx.bezierCurveTo(16, 18, 16, 13, 16, 10);
            ctx.bezierCurveTo(16, 9, 16, 6, 16, 5);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 15:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 43);
            ctx.bezierCurveTo(10, 43, 13, 42, 16, 42);
            ctx.bezierCurveTo(20, 42, 22, 43, 23, 44);
            ctx.bezierCurveTo(23, 42, 23, 39, 23, 38);
            ctx.bezierCurveTo(23, 35, 23, 31, 22, 28);
            ctx.bezierCurveTo(22, 25, 20, 19, 19, 16);
            ctx.bezierCurveTo(18, 13, 16, 7, 15, 3);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 4, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 10, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 8, 31);
            ctx.bezierCurveTo(7, 35, 8, 40, 8, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 14:
          x = x + 2;
          y = y + 7;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 48);
            ctx.bezierCurveTo(10, 48, 13, 46, 16, 46);
            ctx.bezierCurveTo(20, 47, 22, 48, 23, 49);
            ctx.bezierCurveTo(23, 47, 23, 44, 23, 42);
            ctx.bezierCurveTo(23, 39, 23, 35, 22, 31);
            ctx.bezierCurveTo(22, 28, 20, 21, 19, 18);
            ctx.bezierCurveTo(18, 14, 16, 7, 15, 4);
            ctx.bezierCurveTo(15, 3, 14, 2, 13, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 11, 3);
            ctx.bezierCurveTo(11, 5, 10, 9, 10, 11);
            ctx.bezierCurveTo(10, 14, 9, 19, 10, 23);
            ctx.bezierCurveTo(9, 26, 8, 30, 8, 35);
            ctx.bezierCurveTo(7, 39, 8, 45, 8, 48);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 13:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 12:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 11:
          x = x + 4;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 21:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 22:
          x = x + 7;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 23:
          x = x + 7;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 24:
          x = x + 9;
          y = y + 4;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 25:
          x = x + 12;
          y = y + 14;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(2, 41);
            ctx.bezierCurveTo(3, 41, 8, 40, 10, 40);
            ctx.bezierCurveTo(12, 40, 14, 41, 15, 42);
            ctx.bezierCurveTo(15, 40, 14, 37, 14, 36);
            ctx.bezierCurveTo(14, 34, 14, 31, 14, 28);
            ctx.bezierCurveTo(13, 25, 12, 20, 12, 18);
            ctx.bezierCurveTo(11, 15, 13, 11, 13, 7);
            ctx.bezierCurveTo(13, 7, 13, 5, 12, 5);
            ctx.bezierCurveTo(11, 4, 10, 5, 10, 6);
            ctx.bezierCurveTo(9, 7, 7, 8, 7, 10);
            ctx.bezierCurveTo(6, 13, 4, 18, 4, 23);
            ctx.bezierCurveTo(4, 26, 3, 27, 3, 31);
            ctx.bezierCurveTo(2, 33, 1, 37, 2, 41);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 26:
          x = x + 0;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 27:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 28:
          x = x + 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(10, 41);
            ctx.bezierCurveTo(12, 41, 17, 41, 20, 41);
            ctx.bezierCurveTo(21, 41, 26, 42, 27, 43);
            ctx.bezierCurveTo(27, 40, 27, 35, 27, 33);
            ctx.bezierCurveTo(27, 32, 28, 27, 28, 24);
            ctx.bezierCurveTo(29, 22, 30, 19, 31, 17);
            ctx.bezierCurveTo(31, 15, 32, 13, 32, 12);
            ctx.bezierCurveTo(32, 10, 31, 9, 29, 7);
            ctx.bezierCurveTo(27, 7, 27, 7, 26, 8);
            ctx.bezierCurveTo(25, 9, 23, 12, 22, 13);
            ctx.bezierCurveTo(21, 15, 17, 19, 16, 22);
            ctx.bezierCurveTo(15, 26, 12, 31, 11, 34);
            ctx.bezierCurveTo(10, 36, 10, 39, 10, 41);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 55:
          x = x + 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 44);
            ctx.bezierCurveTo(10, 44, 23, 44, 29, 44);
            ctx.bezierCurveTo(28, 42, 27, 38, 27, 35);
            ctx.bezierCurveTo(28, 33, 28, 27, 29, 24);
            ctx.bezierCurveTo(28, 21, 28, 15, 26, 12);
            ctx.bezierCurveTo(25, 10, 24, 6, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 13, 18, 21, 18, 25);
            ctx.bezierCurveTo(17, 26, 16, 29, 16, 30);
            ctx.bezierCurveTo(16, 28, 15, 24, 14, 20);
            ctx.bezierCurveTo(14, 16, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 6, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 10);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 18, 5, 23, 5, 25);
            ctx.bezierCurveTo(5, 28, 5, 32, 5, 34);
            ctx.bezierCurveTo(6, 36, 5, 39, 5, 41);
            ctx.bezierCurveTo(4, 42, 4, 43, 4, 44);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 54:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(4, 46);
            ctx.bezierCurveTo(10, 46, 23, 46, 29, 46);
            ctx.bezierCurveTo(28, 44, 27, 40, 27, 37);
            ctx.bezierCurveTo(28, 34, 28, 28, 29, 25);
            ctx.bezierCurveTo(28, 21, 28, 16, 26, 13);
            ctx.bezierCurveTo(25, 10, 24, 7, 22, 5);
            ctx.bezierCurveTo(20, 5, 20, 5, 19, 6);
            ctx.bezierCurveTo(18, 7, 18, 8, 19, 10);
            ctx.bezierCurveTo(19, 14, 18, 22, 18, 26);
            ctx.bezierCurveTo(17, 27, 16, 30, 16, 32);
            ctx.bezierCurveTo(16, 29, 15, 25, 14, 21);
            ctx.bezierCurveTo(14, 17, 11, 12, 10, 9);
            ctx.bezierCurveTo(9, 8, 9, 7, 7, 5);
            ctx.bezierCurveTo(5, 5, 5, 6, 3, 6);
            ctx.bezierCurveTo(3, 8, 3, 9, 3, 11);
            ctx.bezierCurveTo(3, 12, 4, 13, 4, 16);
            ctx.bezierCurveTo(5, 19, 5, 24, 5, 26);
            ctx.bezierCurveTo(5, 29, 5, 33, 5, 36);
            ctx.bezierCurveTo(6, 38, 5, 41, 5, 43);
            ctx.bezierCurveTo(4, 44, 4, 45, 4, 46);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(18, 10);
            ctx.bezierCurveTo(17, 9, 16, 7, 15, 6);
            ctx.bezierCurveTo(14, 6, 12, 6, 11, 6);
            ctx.bezierCurveTo(11, 7, 10, 8, 10, 9);
            ctx.bezierCurveTo(10, 10, 11, 11, 11, 12);
            ctx.bezierCurveTo(12, 13, 13, 16, 13, 17);
            ctx.bezierCurveTo(14, 19, 15, 22, 15, 23);
            ctx.bezierCurveTo(15, 25, 16, 28, 16, 30);
            ctx.bezierCurveTo(17, 28, 18, 24, 18, 22);
            ctx.bezierCurveTo(18, 20, 18, 15, 18, 12);
            ctx.bezierCurveTo(18, 12, 18, 11, 18, 10);
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 53:
          x = x + 3;
          y = y + 2;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 57);
            ctx.bezierCurveTo(10, 57, 13, 55, 16, 55);
            ctx.bezierCurveTo(19, 55, 21, 57, 22, 58);
            ctx.bezierCurveTo(22, 56, 22, 52, 22, 49);
            ctx.bezierCurveTo(22, 46, 22, 41, 21, 37);
            ctx.bezierCurveTo(21, 33, 19, 25, 19, 21);
            ctx.bezierCurveTo(18, 17, 16, 8, 15, 4);
            ctx.bezierCurveTo(14, 3, 14, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 3, 11, 3);
            ctx.bezierCurveTo(10, 6, 10, 10, 10, 13);
            ctx.bezierCurveTo(10, 16, 9, 22, 10, 27);
            ctx.bezierCurveTo(9, 31, 8, 36, 7, 41);
            ctx.bezierCurveTo(7, 46, 8, 53, 8, 57);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 52:
          x = x + 5;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 43);
            ctx.bezierCurveTo(9, 43, 11, 42, 13, 42);
            ctx.bezierCurveTo(16, 42, 17, 43, 18, 44);
            ctx.bezierCurveTo(18, 42, 18, 39, 18, 38);
            ctx.bezierCurveTo(18, 35, 18, 31, 18, 28);
            ctx.bezierCurveTo(17, 25, 16, 19, 16, 16);
            ctx.bezierCurveTo(15, 13, 13, 7, 13, 3);
            ctx.bezierCurveTo(12, 3, 12, 2, 11, 1);
            ctx.bezierCurveTo(10, 2, 10, 2, 10, 3);
            ctx.bezierCurveTo(9, 4, 9, 8, 9, 10);
            ctx.bezierCurveTo(9, 13, 9, 17, 9, 21);
            ctx.bezierCurveTo(9, 24, 8, 27, 7, 31);
            ctx.bezierCurveTo(7, 35, 7, 40, 7, 43);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 51:
          x = x + 2;
          y = y + 10;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(8, 45);
            ctx.bezierCurveTo(9, 45, 12, 44, 15, 44);
            ctx.bezierCurveTo(18, 44, 20, 45, 21, 46);
            ctx.bezierCurveTo(21, 44, 21, 41, 21, 39);
            ctx.bezierCurveTo(21, 37, 21, 33, 20, 29);
            ctx.bezierCurveTo(20, 26, 19, 20, 18, 17);
            ctx.bezierCurveTo(17, 13, 15, 7, 14, 3);
            ctx.bezierCurveTo(14, 3, 13, 2, 12, 1);
            ctx.bezierCurveTo(11, 2, 11, 2, 10, 3);
            ctx.bezierCurveTo(10, 5, 10, 8, 10, 10);
            ctx.bezierCurveTo(10, 13, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 7, 33);
            ctx.bezierCurveTo(7, 37, 8, 42, 8, 45);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 61:
          x = x + 6;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(9, 46, 14, 44, 18, 44);
            ctx.bezierCurveTo(21, 44, 24, 45, 26, 46);
            ctx.bezierCurveTo(26, 44, 25, 41, 25, 39);
            ctx.bezierCurveTo(25, 37, 25, 33, 24, 29);
            ctx.bezierCurveTo(23, 26, 22, 20, 21, 17);
            ctx.bezierCurveTo(20, 13, 22, 7, 21, 3);
            ctx.bezierCurveTo(20, 3, 20, 1, 19, 0);
            ctx.bezierCurveTo(17, 1, 16, 1, 15, 1);
            ctx.bezierCurveTo(15, 3, 13, 7, 13, 9);
            ctx.bezierCurveTo(13, 12, 10, 17, 11, 22);
            ctx.bezierCurveTo(10, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(8, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 62:
          x = x + 5;
          y = y + 12;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 46);
            ctx.bezierCurveTo(8, 46, 12, 44, 14, 44);
            ctx.bezierCurveTo(16, 44, 18, 45, 20, 46);
            ctx.bezierCurveTo(20, 44, 19, 41, 19, 39);
            ctx.bezierCurveTo(19, 37, 19, 33, 18, 29);
            ctx.bezierCurveTo(18, 26, 17, 20, 16, 17);
            ctx.bezierCurveTo(15, 13, 17, 7, 16, 3);
            ctx.bezierCurveTo(16, 3, 16, 1, 15, 0);
            ctx.bezierCurveTo(14, 1, 13, 1, 12, 1);
            ctx.bezierCurveTo(12, 3, 11, 7, 11, 9);
            ctx.bezierCurveTo(11, 12, 9, 17, 9, 22);
            ctx.bezierCurveTo(9, 25, 8, 29, 8, 33);
            ctx.bezierCurveTo(7, 37, 7, 43, 7, 46);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 63:
          x = x + 5;
          y = y + 3;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 53);
            ctx.bezierCurveTo(9, 52, 13, 51, 15, 51);
            ctx.bezierCurveTo(18, 51, 21, 52, 22, 53);
            ctx.bezierCurveTo(22, 51, 22, 47, 22, 45);
            ctx.bezierCurveTo(21, 42, 21, 38, 21, 34);
            ctx.bezierCurveTo(20, 30, 19, 23, 18, 19);
            ctx.bezierCurveTo(17, 15, 19, 8, 18, 4);
            ctx.bezierCurveTo(18, 3, 18, 1, 16, 0);
            ctx.bezierCurveTo(15, 1, 14, 1, 13, 1);
            ctx.bezierCurveTo(13, 3, 12, 8, 12, 10);
            ctx.bezierCurveTo(11, 14, 10, 20, 10, 25);
            ctx.bezierCurveTo(9, 28, 8, 33, 8, 38);
            ctx.bezierCurveTo(8, 43, 7, 49, 7, 53);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 64:
          x = x + 1;
          y = y + 16;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 43);
            ctx.bezierCurveTo(12, 43, 21, 44, 27, 44);
            ctx.bezierCurveTo(26, 41, 24, 32, 24, 29);
            ctx.bezierCurveTo(23, 26, 27, 14, 28, 9);
            ctx.bezierCurveTo(28, 7, 28, 7, 27, 5);
            ctx.bezierCurveTo(26, 4, 27, 4, 25, 4);
            ctx.bezierCurveTo(24, 6, 24, 8, 23, 9);
            ctx.bezierCurveTo(22, 12, 19, 19, 18, 23);
            ctx.bezierCurveTo(18, 24, 17, 26, 17, 27);
            ctx.bezierCurveTo(17, 25, 15, 20, 15, 18);
            ctx.bezierCurveTo(15, 16, 16, 12, 16, 11);
            ctx.bezierCurveTo(17, 9, 18, 7, 19, 4);
            ctx.bezierCurveTo(18, 2, 19, 3, 17, 2);
            ctx.bezierCurveTo(16, 1, 16, 1, 14, 2);
            ctx.bezierCurveTo(12, 4, 10, 8, 9, 11);
            ctx.bezierCurveTo(8, 15, 8, 19, 7, 22);
            ctx.bezierCurveTo(7, 25, 6, 30, 6, 33);
            ctx.bezierCurveTo(6, 37, 7, 41, 6, 43);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(23, 8);
            ctx.bezierCurveTo(23, 7, 23, 6, 23, 5);
            ctx.bezierCurveTo(22, 5, 21, 4, 20, 4);
            ctx.bezierCurveTo(20, 4, 19, 5, 18, 5);
            ctx.bezierCurveTo(18, 7, 17, 10, 17, 12);
            ctx.bezierCurveTo(17, 13, 16, 16, 15, 17);
            ctx.bezierCurveTo(16, 19, 17, 22, 17, 24);
            ctx.bezierCurveTo(17, 25, 17, 26, 17, 26);
            ctx.bezierCurveTo(18, 24, 19, 20, 19, 18);
            ctx.bezierCurveTo(20, 17, 21, 14, 21, 13);
            ctx.bezierCurveTo(22, 12, 23, 10, 23, 9);
            ctx.bezierCurveTo(23, 9, 23, 8, 23, 8);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 65:
          x = x - 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(13, 46);
            ctx.bezierCurveTo(18, 44, 26, 45, 32, 45);
            ctx.bezierCurveTo(31, 43, 31, 34, 32, 32);
            ctx.bezierCurveTo(31, 29, 34, 20, 35, 15);
            ctx.bezierCurveTo(35, 14, 35, 12, 34, 10);
            ctx.bezierCurveTo(33, 10, 32, 10, 31, 11);
            ctx.bezierCurveTo(30, 12, 28, 15, 28, 16);
            ctx.bezierCurveTo(27, 19, 24, 25, 23, 27);
            ctx.bezierCurveTo(23, 28, 23, 30, 22, 31);
            ctx.bezierCurveTo(22, 29, 20, 25, 21, 23);
            ctx.bezierCurveTo(21, 22, 21, 19, 22, 17);
            ctx.bezierCurveTo(22, 16, 23, 14, 24, 12);
            ctx.bezierCurveTo(23, 10, 24, 11, 23, 10);
            ctx.bezierCurveTo(22, 9, 21, 9, 20, 10);
            ctx.bezierCurveTo(18, 12, 16, 15, 15, 18);
            ctx.bezierCurveTo(15, 21, 15, 24, 14, 27);
            ctx.bezierCurveTo(14, 29, 13, 34, 13, 36);
            ctx.bezierCurveTo(13, 39, 14, 43, 13, 46);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(28, 14);
            ctx.bezierCurveTo(28, 13, 28, 12, 28, 11);
            ctx.bezierCurveTo(27, 11, 25, 12, 24, 12);
            ctx.bezierCurveTo(24, 14, 23, 18, 22, 20);
            ctx.bezierCurveTo(22, 21, 21, 22, 21, 23);
            ctx.bezierCurveTo(21, 25, 22, 28, 22, 29);
            ctx.bezierCurveTo(23, 26, 26, 21, 27, 18);
            ctx.bezierCurveTo(27, 17, 28, 15, 28, 14);
            ctx.closePath();
            ctx.stroke();
            ctx.fillStyle = '#080080';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 48:
          x = x + 2;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, 12);
            ctx.bezierCurveTo(8, 13, 10, 12, 13, 13);
            ctx.bezierCurveTo(17, 12, 20, 12, 23, 12);
            ctx.bezierCurveTo(23, 16, 23, 22, 22, 26);
            ctx.bezierCurveTo(21, 30, 18, 35, 16, 38);
            ctx.bezierCurveTo(15, 40, 12, 43, 11, 43);
            ctx.bezierCurveTo(9, 42, 9, 42, 9, 41);
            ctx.bezierCurveTo(10, 39, 11, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 13, 30, 14, 28);
            ctx.bezierCurveTo(14, 27, 14, 23, 14, 21);
            ctx.bezierCurveTo(14, 23, 13, 28, 13, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 11, 35);
            ctx.bezierCurveTo(11, 36, 9, 39, 9, 40);
            ctx.bezierCurveTo(8, 40, 6, 41, 5, 42);
            ctx.bezierCurveTo(5, 42, 4, 43, 3, 42);
            ctx.bezierCurveTo(2, 42, 2, 42, 1, 40);
            ctx.bezierCurveTo(2, 38, 2, 37, 2, 37);
            ctx.bezierCurveTo(3, 36, 3, 36, 4, 35);
            ctx.bezierCurveTo(4, 34, 6, 31, 6, 30);
            ctx.bezierCurveTo(6, 29, 6, 28, 7, 25);
            ctx.bezierCurveTo(6, 23, 7, 20, 7, 16);
            ctx.bezierCurveTo(7, 14, 6, 14, 6, 12);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 47:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 46:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 45:
          x = x + 11;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 0);
            ctx.bezierCurveTo(3, 0, 7, 1, 9, 1);
            ctx.bezierCurveTo(11, 1, 14, 0, 16, 0);
            ctx.bezierCurveTo(16, 7, 15, 20, 14, 27);
            ctx.bezierCurveTo(14, 31, 13, 37, 12, 41);
            ctx.bezierCurveTo(11, 43, 7, 46, 5, 47);
            ctx.bezierCurveTo(4, 47, 3, 47, 3, 45);
            ctx.bezierCurveTo(3, 43, 4, 40, 5, 37);
            ctx.bezierCurveTo(5, 34, 3, 23, 4, 19);
            ctx.bezierCurveTo(3, 18, 1, 5, 0, 0);
            ctx.bezierCurveTo(1, 0, 0, 0, 1, 0);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 44:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(3, 3);
            ctx.bezierCurveTo(5, 3, 7, 5, 9, 5);
            ctx.bezierCurveTo(11, 5, 14, 3, 16, 3);
            ctx.bezierCurveTo(17, 14, 13, 34, 11, 45);
            ctx.bezierCurveTo(10, 46, 9, 46, 8, 45);
            ctx.bezierCurveTo(5, 34, 3, 14, 3, 3);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 43:
          x = x + 3;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 42:
          x = x + 13;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 41:
          x = x + 11;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 38:
          x = x + 11;
          y = y + 15;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(18, 12);
            ctx.bezierCurveTo(16, 13, 14, 12, 11, 13);
            ctx.bezierCurveTo(7, 12, 4, 12, 1, 12);
            ctx.bezierCurveTo(1, 16, 1, 22, 2, 26);
            ctx.bezierCurveTo(3, 30, 6, 35, 8, 38);
            ctx.bezierCurveTo(9, 40, 12, 43, 13, 43);
            ctx.bezierCurveTo(15, 42, 15, 42, 15, 41);
            ctx.bezierCurveTo(14, 39, 13, 37, 12, 36);
            ctx.bezierCurveTo(12, 34, 11, 30, 10, 28);
            ctx.bezierCurveTo(10, 27, 10, 23, 10, 21);
            ctx.bezierCurveTo(10, 23, 11, 28, 11, 31);
            ctx.bezierCurveTo(12, 32, 12, 34, 13, 35);
            ctx.bezierCurveTo(13, 36, 15, 39, 15, 40);
            ctx.bezierCurveTo(16, 40, 18, 41, 19, 42);
            ctx.bezierCurveTo(19, 42, 20, 43, 21, 42);
            ctx.bezierCurveTo(22, 42, 22, 42, 23, 40);
            ctx.bezierCurveTo(22, 38, 22, 37, 22, 37);
            ctx.bezierCurveTo(21, 36, 21, 36, 20, 35);
            ctx.bezierCurveTo(20, 34, 18, 31, 18, 30);
            ctx.bezierCurveTo(18, 29, 18, 28, 17, 25);
            ctx.bezierCurveTo(18, 23, 17, 20, 17, 16);
            ctx.bezierCurveTo(17, 14, 18, 14, 18, 12);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 37:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 36:
          x = x + 6;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 35:
          x = x + 9;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(15, 0);
            ctx.bezierCurveTo(13, 0, 9, 1, 7, 1);
            ctx.bezierCurveTo(5, 1, 2, 0, 0, 0);
            ctx.bezierCurveTo(0, 7, 1, 20, 2, 27);
            ctx.bezierCurveTo(2, 31, 3, 37, 4, 41);
            ctx.bezierCurveTo(5, 43, 9, 46, 11, 47);
            ctx.bezierCurveTo(12, 47, 13, 47, 13, 45);
            ctx.bezierCurveTo(13, 43, 12, 40, 11, 37);
            ctx.bezierCurveTo(11, 34, 13, 23, 12, 19);
            ctx.bezierCurveTo(13, 18, 15, 5, 16, 0);
            ctx.bezierCurveTo(15, 0, 16, 0, 15, 0);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 34:
          x = x + 8;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(16, 3);
            ctx.bezierCurveTo(14, 3, 12, 5, 10, 5);
            ctx.bezierCurveTo(8, 5, 5, 3, 3, 3);
            ctx.bezierCurveTo(2, 14, 6, 34, 8, 45);
            ctx.bezierCurveTo(9, 46, 10, 46, 11, 45);
            ctx.bezierCurveTo(14, 34, 16, 14, 16, 3);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 33:
          x = x - 1;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 32:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 31:
          x = x + 14;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 85:
          x = x + 2;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(6, -1);
            ctx.bezierCurveTo(9, 0, 14, 0, 17, 0);
            ctx.bezierCurveTo(20, 0, 25, -1, 27, -1);
            ctx.bezierCurveTo(28, 3, 29, 10, 29, 14);
            ctx.bezierCurveTo(29, 19, 25, 27, 23, 31);
            ctx.bezierCurveTo(21, 33, 19, 36, 17, 36);
            ctx.bezierCurveTo(16, 36, 15, 36, 14, 35);
            ctx.bezierCurveTo(13, 33, 15, 32, 15, 30);
            ctx.bezierCurveTo(16, 27, 19, 24, 20, 21);
            ctx.bezierCurveTo(20, 20, 20, 15, 20, 12);
            ctx.bezierCurveTo(20, 11, 19, 10, 18, 9);
            ctx.bezierCurveTo(17, 10, 15, 12, 14, 13);
            ctx.bezierCurveTo(13, 14, 12, 17, 11, 18);
            ctx.bezierCurveTo(10, 21, 8, 25, 7, 27);
            ctx.bezierCurveTo(6, 29, 7, 32, 6, 34);
            ctx.bezierCurveTo(6, 35, 6, 37, 5, 38);
            ctx.bezierCurveTo(4, 39, 4, 39, 3, 38);
            ctx.bezierCurveTo(1, 37, 0, 36, 0, 33);
            ctx.bezierCurveTo(-1, 30, -1, 24, -1, 21);
            ctx.bezierCurveTo(0, 18, 1, 16, 2, 13);
            ctx.bezierCurveTo(3, 11, 5, 7, 5, 4);
            ctx.bezierCurveTo(6, 3, 6, 1, 6, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 84:
          x = x + 2;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, -1);
            ctx.bezierCurveTo(10, 0, 16, 0, 19, 0);
            ctx.bezierCurveTo(22, 0, 27, -1, 30, -1);
            ctx.bezierCurveTo(31, 3, 32, 11, 32, 16);
            ctx.bezierCurveTo(32, 21, 28, 30, 25, 35);
            ctx.bezierCurveTo(24, 37, 21, 40, 19, 40);
            ctx.bezierCurveTo(18, 40, 15, 42, 14, 40);
            ctx.bezierCurveTo(14, 38, 16, 36, 17, 33);
            ctx.bezierCurveTo(18, 30, 21, 27, 22, 23);
            ctx.bezierCurveTo(22, 22, 22, 16, 22, 14);
            ctx.bezierCurveTo(22, 13, 21, 11, 20, 10);
            ctx.bezierCurveTo(19, 11, 17, 13, 16, 15);
            ctx.bezierCurveTo(15, 16, 11, 18, 10, 20);
            ctx.bezierCurveTo(9, 22, 7, 28, 7, 30);
            ctx.bezierCurveTo(6, 34, 6, 35, 6, 38);
            ctx.bezierCurveTo(7, 39, 7, 41, 6, 42);
            ctx.bezierCurveTo(5, 43, 4, 43, 3, 42);
            ctx.bezierCurveTo(1, 41, 0, 39, 0, 37);
            ctx.bezierCurveTo(-1, 33, -1, 27, -1, 23);
            ctx.bezierCurveTo(0, 20, 3, 18, 4, 15);
            ctx.bezierCurveTo(5, 12, 6, 8, 6, 5);
            ctx.bezierCurveTo(7, 3, 7, 1, 7, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 83:
          x = x + 4;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(7, 12);
            ctx.bezierCurveTo(9, 12, 13, 13, 17, 13);
            ctx.bezierCurveTo(21, 14, 24, 13, 27, 12);
            ctx.bezierCurveTo(26, 20, 23, 36, 21, 44);
            ctx.bezierCurveTo(19, 49, 15, 59, 12, 64);
            ctx.bezierCurveTo(11, 65, 10, 65, 9, 64);
            ctx.bezierCurveTo(7, 56, 9, 41, 8, 33);
            ctx.bezierCurveTo(8, 28, 7, 17, 7, 12);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 82:
          x = x + 12;
          y = y + 28;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 81:
          x = x + 7;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 10, 4, 30, 6, 39);
            ctx.bezierCurveTo(7, 40, 8, 40, 9, 40);
            ctx.bezierCurveTo(11, 30, 12, 11, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 71:
          x = x + 15;
          y = y + 29;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(1, 1);
            ctx.bezierCurveTo(4, 1, 9, 1, 12, 1);
            ctx.bezierCurveTo(12, 10, 9, 30, 7, 39);
            ctx.bezierCurveTo(6, 40, 5, 40, 4, 40);
            ctx.bezierCurveTo(2, 30, 1, 11, 1, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 72:
          x = x + 10;
          y = y + 30;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(12, 1);
            ctx.bezierCurveTo(9, 1, 4, 1, 1, 1);
            ctx.bezierCurveTo(1, 13, 4, 37, 6, 48);
            ctx.bezierCurveTo(7, 49, 8, 50, 9, 49);
            ctx.bezierCurveTo(11, 37, 12, 13, 12, 1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 73:
          x = x - 0;
          y = y + 18;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(27, 12);
            ctx.bezierCurveTo(25, 12, 21, 13, 17, 13);
            ctx.bezierCurveTo(13, 14, 10, 13, 7, 12);
            ctx.bezierCurveTo(8, 20, 11, 36, 13, 44);
            ctx.bezierCurveTo(15, 49, 19, 59, 22, 64);
            ctx.bezierCurveTo(23, 65, 24, 65, 25, 64);
            ctx.bezierCurveTo(27, 56, 25, 41, 26, 33);
            ctx.bezierCurveTo(26, 28, 27, 17, 27, 12);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 74:
          x = x + 7;
          y = y + 26;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(24, -1);
            ctx.bezierCurveTo(21, 0, 15, 0, 12, 0);
            ctx.bezierCurveTo(9, 0, 4, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 11, -1, 16);
            ctx.bezierCurveTo(-1, 21, 3, 30, 6, 35);
            ctx.bezierCurveTo(7, 37, 10, 40, 12, 40);
            ctx.bezierCurveTo(13, 40, 16, 42, 17, 40);
            ctx.bezierCurveTo(17, 38, 15, 36, 14, 33);
            ctx.bezierCurveTo(13, 30, 10, 27, 9, 23);
            ctx.bezierCurveTo(9, 22, 9, 16, 9, 14);
            ctx.bezierCurveTo(9, 13, 10, 11, 11, 10);
            ctx.bezierCurveTo(12, 11, 14, 13, 15, 15);
            ctx.bezierCurveTo(16, 16, 20, 18, 21, 20);
            ctx.bezierCurveTo(22, 22, 24, 28, 24, 30);
            ctx.bezierCurveTo(25, 34, 25, 35, 25, 38);
            ctx.bezierCurveTo(24, 39, 24, 41, 25, 42);
            ctx.bezierCurveTo(26, 43, 27, 43, 28, 42);
            ctx.bezierCurveTo(30, 41, 31, 39, 31, 37);
            ctx.bezierCurveTo(32, 33, 32, 27, 32, 23);
            ctx.bezierCurveTo(31, 20, 28, 18, 27, 15);
            ctx.bezierCurveTo(26, 12, 25, 8, 25, 5);
            ctx.bezierCurveTo(24, 3, 24, 1, 24, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        case 75:
          x = x + 7;
          y = y + 24;
          draw = (ctx, shape) => {
            ctx.strokeStyle = '#5c5c5c';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(22, -1);
            ctx.bezierCurveTo(19, 0, 14, 0, 11, 0);
            ctx.bezierCurveTo(8, 0, 3, -1, 1, -1);
            ctx.bezierCurveTo(0, 3, -1, 10, -1, 14);
            ctx.bezierCurveTo(-1, 19, 3, 27, 5, 31);
            ctx.bezierCurveTo(7, 33, 9, 36, 11, 36);
            ctx.bezierCurveTo(12, 36, 13, 36, 14, 35);
            ctx.bezierCurveTo(15, 33, 13, 32, 13, 30);
            ctx.bezierCurveTo(12, 27, 9, 24, 8, 21);
            ctx.bezierCurveTo(8, 20, 8, 15, 8, 12);
            ctx.bezierCurveTo(8, 11, 9, 10, 10, 9);
            ctx.bezierCurveTo(11, 10, 13, 12, 14, 13);
            ctx.bezierCurveTo(15, 14, 16, 17, 17, 18);
            ctx.bezierCurveTo(18, 21, 20, 25, 21, 27);
            ctx.bezierCurveTo(22, 29, 21, 32, 22, 34);
            ctx.bezierCurveTo(22, 35, 22, 37, 23, 38);
            ctx.bezierCurveTo(24, 39, 24, 39, 25, 38);
            ctx.bezierCurveTo(27, 37, 28, 36, 28, 33);
            ctx.bezierCurveTo(29, 30, 29, 24, 29, 21);
            ctx.bezierCurveTo(28, 18, 27, 16, 26, 13);
            ctx.bezierCurveTo(25, 11, 23, 7, 23, 4);
            ctx.bezierCurveTo(22, 3, 22, 1, 22, -1);
            ctx.stroke();
            ctx.fillStyle = '#00ff09';
            ctx.fill();
            ctx.fillStrokeShape(shape);
          };
          break;
        default:
          break;
      }
      break;
    case all.OBSERVACION:
      // pinta el diente del odontograma dependiendo del caso y color
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#ff0000';
        ctx.textAlign = 'center';
        ctx.font = '20px Arial Bold';
        ctx.fontStyle = 'bold';
        if (type === 0) {
          ctx.fillText('OBS', 10, 45);
        } else {
          ctx.fillText('OBS', 10, 5);
        }
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CARIES:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      // draw = (ctx, shape) => {
      //   ctx.strokeStyle = '#ff0000';
      //   ctx.lineWidth = 3;
      //   ctx.beginPath();
      //   ctx.fillStyle = '#ff0000';
      //   ctx.textAlign = 'center';
      //   ctx.font = '15px Arial Bold';
      //   ctx.fontStyle = 'bold';
      //   ctx.fillText('OBS', 10, positionTextY);
      //   ctx.stroke();
      //   ctx.fillStrokeShape(shape);
      // };
      break;
    case all.CARIES_COMPLETADO:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      // draw = (ctx, shape) => {
      //   ctx.strokeStyle = '#0000ff';
      //   ctx.lineWidth = 3;
      //   ctx.beginPath();
      //   ctx.fillStyle = '#0000ff';
      //   ctx.textAlign = 'center';
      //   ctx.font = '15px Arial Bold';
      //   ctx.fontStyle = 'bold';
      //   ctx.fillText('RF', 10, positionTextY);
      //   ctx.stroke();
      //   ctx.fillStrokeShape(shape);
      // };
      break;
    case all.COMPLETADO:
      // pinta el diente del odontograma dependiendo del caso y color
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#22b14d';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#22b14d';
        ctx.textAlign = 'center';
        ctx.font = '25px Arial Bold';
        ctx.fontStyle = 'bold';
        if (type === 0) {
          ctx.fillText('RF', 10, 45);
        } else {
          ctx.fillText('RF', 10, 5);
        }
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DESGASTE_OCLUSAL_INCISAL:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('DES', 13, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_DISCR0MICO:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('DIS', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.DIENTE_ECTOPICO:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('E', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.IMPACTACION:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('I', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.IMPLANTE:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('IMP', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.MACRODONCIA:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('MAC', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.MICRODONCIA:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('MIC', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.AMALGAMA:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('AM', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.RESINA:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('R', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.IONOMERO_VIDRIO:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('IV', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.INCRUSTACION_METALICA:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('IM', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.INCRUSTACION_ESTETICA:
      if (type === 0) {
        x = x + 10;
        y = y + 0;
      } else {
        x = x + 10;
        y = y + 43;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.fillStyle = '#0000ff';
        ctx.textAlign = 'center';
        ctx.font = '15px Arial Bold';
        ctx.fontStyle = 'bold';
        ctx.fillText('IE', 10, positionTextY);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CIRUGIA_INICIO:
      if (type === 0) {
        x = x + 3;
        y = y + 3;
      } else {
        x = x + 3;
        y = y + 20;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#FF0000';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(32, 63);
        ctx.moveTo(32, 3);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CIRUGIA_TERMINO:
      if (type === 0) {
        x = x + 3;
        y = y + 3;
      } else {
        x = x + 3;
        y = y + 20;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#e7ed2d';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(32, 63);
        ctx.moveTo(32, 3);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    case all.CIRUGIA_RETIRO_PTS_CONTROL_POS:
      if (type === 0) {
        x = x + 3;
        y = y + 3;
      } else {
        x = x + 3;
        y = y + 20;
      }
      draw = (ctx, shape) => {
        ctx.strokeStyle = '#009105';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(2, 3);
        ctx.lineTo(32, 63);
        ctx.moveTo(32, 3);
        ctx.lineTo(2, 63);
        ctx.stroke();
        ctx.fillStrokeShape(shape);
      };
      break;
    default:
      break;
  }

  return {
    id: id,
    draw: draw,
    damage: damage,
    breakStart: breakStart,
    breakEnd: breakEnd,
    point: point,
    x: x,
    y: y,
    type: type,
    id_cita: id_cita,
    date: date,
    especialista: especialista,
    especialista_id: especialista_id,
    asunto: asunto,
  };
};
