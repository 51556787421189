import React, { Fragment, useState } from 'react';
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Input,
  Button,
} from 'reactstrap';
import Loarder from '../../../../../components/Loader';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import Url from '../../../../../config/Url';
import SweetAlert from 'sweetalert2';

const Actividad = ({ loading, tarde, actividadState, setActividad }) => {
  const socket = useSelector((state) => state.Socket.socket);
  const role_id = useSelector((state) => state.Auth.data.role_user_id);
  const staff = useSelector((state) => state.Auth.data.id);
  const idSucursal = useSelector((state) => state.Auth.data.sucursal_id);
  const token = useSelector((state) => state.Auth.data.access_token);
  const id_paciente = useSelector((state) => state.PatientData.id_paciente);
  const nombrePaciente = useSelector(
    (state) => state.PatientData.nombre_paciente
  );
  const { id_cita } = useSelector((state) => state.PatientData.id_cita);
  const [disable, setDisable] = useState(false);
  const [buttonText, setButtonText] = useState('SOLICITAR CALIFICACION');
  const handleCalificacion = () => {
    SweetAlert.fire({
      title: 'Estas seguro?',
      text: 'Se enviara la solicitud para calificarte!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, estoy seguro',
    }).then((result) => {
      if (result.isConfirmed) {
        setDisable(true);
        setButtonText('SOLICITUD ENVIADA');
        socket.emit('parlante-mensaje', {
          type: 'mensaje-personalizado',
          payload: {
            data: `${nombrePaciente} por favor acerquese a calificar a su especialista en el area de buzon de la clinica, gracias`,
            idSucursal,
          },
        });
        console.log(id_paciente);
        const data = {
          modalOpen: true,
          paciente_id: id_paciente,
          role_user_id: role_id,
          staff_id: staff,
          cita: id_cita,
          sucursal: idSucursal,
          nombrePac: nombrePaciente,
        };
        socket.emit('openModal', data);
        console.log('modal:', data);

        SweetAlert.fire(
          'Enviado!',
          'Tu solicitud se envio con exito',
          'success'
        );
      }
    });
  };
  const validarCitaforCalificacion = async () => {
    console.log(id_cita, nombrePaciente);
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const res = await Axios.post(
        `${Url}consultaCalificacion`,
        { id_cita, role_id, staff },
        config
      );
      const response = await res.data;
      if (response.success) {
        console.log('estadoCalificacion', response);
        if (response.estadoCalificacion?.pri_calificacion === 1) {
          handleCalificacion();
        } else {
          console.log(
            'no tiene autorizacion para que el paciente califique su atencion'
          );
        }
      } else {
        SweetAlert.fire(
          'Error!',
          'Tu solicitud ya fue enviada, no pueden calificarle dos veces por cita...',
          'error'
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Container>
      {loading ? (
        <Loarder />
      ) : (
        <Fragment>
          {tarde && (
            <Row>
              <Col>
                <FormGroup>
                  <Label for="">Ingrese el motivo de la demora:</Label>
                  <Input
                    type="textarea"
                    placeholder="motivo..."
                    value={actividadState.tarde}
                    onChange={(e) =>
                      setActividad({
                        ...actividadState,
                        tarde: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormGroup>
                <Label for="">Ingrese la actividad realizada:</Label>
                <Input
                  type="textarea"
                  placeholder="actividad..."
                  rows="4"
                  value={actividadState.actividad}
                  onChange={(e) =>
                    setActividad({
                      ...actividadState,
                      actividad: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                    backgroundColor: 'crimson',
                    border: 'none',
                  }}
                  disabled={disable}
                  onClick={validarCitaforCalificacion}
                >
                  {buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  );
};

export default Actividad;
